
var Rive = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  
  return (
function(moduleArg = {}) {

var m = moduleArg, aa, ba;
m.ready = new Promise((a, b) => {
  aa = a;
  ba = b;
});
function ca() {
  function a(g) {
    const n = d;
    c = b = 0;
    d = new Map();
    n.forEach(p => {
      try {
        p(g);
      } catch (l) {
        console.error(l);
      }
    });
    this.kb();
    e && e.Mb();
  }
  let b = 0, c = 0, d = new Map(), e = null, f = null;
  this.requestAnimationFrame = function(g) {
    b || (b = requestAnimationFrame(a.bind(this)));
    const n = ++c;
    d.set(n, g);
    return n;
  };
  this.cancelAnimationFrame = function(g) {
    d.delete(g);
    b && 0 == d.size && (cancelAnimationFrame(b), b = 0);
  };
  this.Kb = function(g) {
    f && (document.body.remove(f), f = null);
    g || (f = document.createElement("div"), f.style.backgroundColor = "black", f.style.position = "fixed", f.style.right = 0, f.style.top = 0, f.style.color = "white", f.style.padding = "4px", f.innerHTML = "RIVE FPS", g = function(n) {
      f.innerHTML = "RIVE FPS " + n.toFixed(1);
    }, document.body.appendChild(f));
    e = new function() {
      let n = 0, p = 0;
      this.Mb = function() {
        var l = performance.now();
        p ? (++n, l -= p, 1000 < l && (g(1000 * n / l), n = p = 0)) : (p = l, n = 0);
      };
    }();
  };
  this.kb = function() {
  };
}
function da() {
  console.assert(!0);
  const a = new Map();
  let b = -Infinity;
  this.push = function(c) {
    c = c + 255 >> 8;
    a.has(c) && clearTimeout(a.get(c));
    a.set(c, setTimeout(function() {
      a.delete(c);
      0 == a.length ? b = -Infinity : c == b && (b = Math.max(...a.keys()), console.assert(b < c));
    }, 1000));
    b = Math.max(c, b);
    return b << 8;
  };
}
const ea = m.onRuntimeInitialized;
m.onRuntimeInitialized = function() {
  ea && ea();
  let a = m.decodeAudio;
  m.decodeAudio = function(e, f) {
    e = a(e);
    f(e);
  };
  let b = m.decodeFont;
  m.decodeFont = function(e, f) {
    e = b(e);
    f(e);
  };
  const c = m.FileAssetLoader;
  m.ptrToAsset = e => {
    let f = m.ptrToFileAsset(e);
    return f.isImage ? m.ptrToImageAsset(e) : f.isFont ? m.ptrToFontAsset(e) : f.isAudio ? m.ptrToAudioAsset(e) : f;
  };
  m.CustomFileAssetLoader = c.extend("CustomFileAssetLoader", {__construct:function({loadContents:e}) {
    this.__parent.__construct.call(this);
    this.Bb = e;
  }, loadContents:function(e, f) {
    e = m.ptrToAsset(e);
    return this.Bb(e, f);
  },});
  m.CDNFileAssetLoader = c.extend("CDNFileAssetLoader", {__construct:function() {
    this.__parent.__construct.call(this);
  }, loadContents:function(e) {
    let f = m.ptrToAsset(e);
    e = f.cdnUuid;
    if ("" === e) {
      return !1;
    }
    (function(g, n) {
      var p = new XMLHttpRequest();
      p.responseType = "arraybuffer";
      p.onreadystatechange = function() {
        4 == p.readyState && 200 == p.status && n(p);
      };
      p.open("GET", g, !0);
      p.send(null);
    })(f.cdnBaseUrl + "/" + e, g => {
      f.decode(new Uint8Array(g.response));
    });
    return !0;
  },});
  m.FallbackFileAssetLoader = c.extend("FallbackFileAssetLoader", {__construct:function() {
    this.__parent.__construct.call(this);
    this.gb = [];
  }, addLoader:function(e) {
    this.gb.push(e);
  }, loadContents:function(e, f) {
    for (let g of this.gb) {
      if (g.loadContents(e, f)) {
        return !0;
      }
    }
    return !1;
  },});
  let d = m.computeAlignment;
  m.computeAlignment = function(e, f, g, n, p = 1.0) {
    return d.call(this, e, f, g, n, p);
  };
};
const fa = m.onRuntimeInitialized;
m.onRuntimeInitialized = function() {
  function a(k) {
    this.F = k;
    this.Ab = k.getContext("2d");
    this.Db = d;
    this.S = [];
    this.la = 0;
    this.clear = function() {
      console.assert(0 == this.la);
      this.S = [];
      e.delete(this);
    };
    this.save = function() {
      ++this.la;
      this.S.push(d.save.bind(d));
    };
    this.restore = function() {
      0 < this.la && (this.S.push(d.restore.bind(d)), --this.la);
    };
    this.transform = function(q) {
      this.S.push(d.transform.bind(d, q));
    };
    this.align = function(q, v, y, C, z = 1.0) {
      this.S.push(d.align.bind(d, q, v, y, C, z));
    };
    this.flush = function() {
      console.assert(0 == this.la);
      e.add(this);
      d.Va || c();
    };
    this["delete"] = function() {
    };
  }
  function b(k, q = !1) {
    var v = {alpha:!0, depth:q, stencil:q, antialias:q, premultipliedAlpha:!0, preserveDrawingBuffer:0, powerPreference:"high-performance", failIfMajorPerformanceCaveat:0, enableExtensionsByDefault:!1, explicitSwapControl:0, renderViaOffscreenBackBuffer:0,};
    q = k.getContext("webgl2", v);
    if (!q) {
      return null;
    }
    v = ha(q, v);
    ia(v);
    const y = f(k.width, k.height);
    y.Ka = v;
    y.F = k;
    y.La = k.width;
    y.Wa = k.height;
    y.T = q;
    var C = y.delete;
    y.delete = function() {
      C.call(this);
      var z = this.Ka;
      r === w[z] && (r = null);
      "object" == typeof JSEvents && JSEvents.Nc(w[z].D.canvas);
      w[z] && w[z].D.canvas && (w[z].D.canvas.zb = void 0);
      this.Ka = this.F = this.La = this.T = w[z] = null;
    };
    return y;
  }
  function c() {
    if (d) {
      var k = d.Cb, q = 0, v = 0, y = 0, C = Array(e.size), z = 0;
      for (var E of e) {
        E.fa = Math.min(E.F.width, k), E.ea = Math.min(E.F.height, k), E.Ia = E.ea * E.fa, q = Math.max(q, E.fa), v = Math.max(v, E.ea), y += E.Ia, C[z++] = E;
      }
      e.clear();
      if (!(0 >= y)) {
        q = 1 << (0 >= q ? 0 : 32 - Math.clz32(q - 1));
        for (v = 1 << (0 >= v ? 0 : 32 - Math.clz32(v - 1)); v * q < y;) {
          q <= v ? q *= 2 : v *= 2;
        }
        q = Math.min(q, k);
        q = Math.min(v, k);
        C.sort((Z, rb) => rb.Ia - Z.Ia);
        y = new m.DynamicRectanizer(k);
        for (E = 0; E < C.length;) {
          y.reset(q, v);
          for (z = E; z < C.length; ++z) {
            var H = C[z], F = y.addRect(H.fa, H.ea);
            if (0 > F) {
              console.assert(z > E);
              break;
            }
            H.ra = F & 65535;
            H.sa = F >> 16;
          }
          H = n.push(y.drawWidth());
          F = p.push(y.drawHeight());
          console.assert(H >= y.drawWidth());
          console.assert(F >= y.drawHeight());
          console.assert(H <= k);
          console.assert(F <= k);
          d.F.width != H && (d.F.width = H);
          d.F.height != F && (d.F.height = F);
          d.clear();
          for (H = E; H < z; ++H) {
            F = C[H];
            d.saveClipRect(F.ra, F.sa, F.ra + F.fa, F.sa + F.ea);
            let Z = new m.Mat2D();
            Z.xx = F.fa / F.F.width;
            Z.yy = F.ea / F.F.height;
            Z.xy = Z.yx = 0;
            Z.tx = F.ra;
            Z.ty = F.sa;
            d.transform(Z);
            for (const rb of F.S) {
              rb();
            }
            d.restoreClipRect();
            F.S = [];
          }
          for (d.flush(); E < z; ++E) {
            H = C[E], F = H.Ab, F.globalCompositeOperation = "copy", F.drawImage(d.F, H.ra, H.sa, H.fa, H.ea, 0, 0, H.F.width, H.F.height);
          }
          E = z;
        }
      }
    }
  }
  fa && fa();
  let d = null;
  const e = new Set(), f = m.makeRenderer;
  m.makeRenderer = function(k, q) {
    if (!d) {
      function v(y) {
        var C = document.createElement("canvas");
        C.width = 1;
        C.height = 1;
        d = b(C, y);
        d.Va = !!d.T.getExtension("WEBGL_shader_pixel_local_storage");
        d.Cb = Math.min(d.T.getParameter(d.T.MAX_RENDERBUFFER_SIZE), d.T.getParameter(d.T.MAX_TEXTURE_SIZE));
        d.Ja = !d.Va;
        if (y = d.T.getExtension("WEBGL_debug_renderer_info")) {
          C = d.T.getParameter(y.UNMASKED_RENDERER_WEBGL), d.T.getParameter(y.UNMASKED_VENDOR_WEBGL).includes("Google") && C.includes("ANGLE Metal Renderer") && (d.Ja = !1);
        }
        return d;
      }
      d = v(!0);
      d.Ja || (d = v(!1));
    }
    return q ? new a(k) : b(k, d.Ja);
  };
  const g = m.Artboard.prototype.draw;
  m.Artboard.prototype.draw = function(k) {
    k.S ? k.S.push(g.bind(this, k.Db)) : g.call(this, k);
  };
  const n = new da(), p = new da(), l = new ca();
  m.requestAnimationFrame = l.requestAnimationFrame.bind(l);
  m.cancelAnimationFrame = l.cancelAnimationFrame.bind(l);
  m.enableFPSCounter = l.Kb.bind(l);
  l.kb = c;
  m.resolveAnimationFrame = c;
  let t = m.load;
  m.load = function(k, q, v = !0) {
    const y = new m.FallbackFileAssetLoader();
    void 0 !== q && y.addLoader(q);
    v && (q = new m.CDNFileAssetLoader(), y.addLoader(q));
    return Promise.resolve(t(k, y));
  };
  const u = m.WebGL2Renderer.prototype.clear;
  m.WebGL2Renderer.prototype.clear = function() {
    ia(this.Ka);
    const k = this.F;
    if (this.La != k.width || this.Wa != k.height) {
      this.resize(k.width, k.height), this.La = k.width, this.Wa = k.height;
    }
    u.call(this);
  };
  m.decodeImage = function(k, q) {
    k = m.decodeWebGL2Image(k);
    q(k);
  };
  let x = m.Renderer.prototype.align;
  m.Renderer.prototype.align = function(k, q, v, y, C = 1.0) {
    x.call(this, k, q, v, y, C);
  };
};
var ja = Object.assign({}, m), ka = "./this.program", la = "object" == typeof window, ma = "function" == typeof importScripts, A = "", na, oa;
if (la || ma) {
  ma ? A = self.location.href : "undefined" != typeof document && document.currentScript && (A = document.currentScript.src), _scriptDir && (A = _scriptDir), 0 !== A.indexOf("blob:") ? A = A.substr(0, A.replace(/[?#].*/, "").lastIndexOf("/") + 1) : A = "", ma && (oa = a => {
    var b = new XMLHttpRequest();
    b.open("GET", a, !1);
    b.responseType = "arraybuffer";
    b.send(null);
    return new Uint8Array(b.response);
  }), na = (a, b, c) => {
    var d = new XMLHttpRequest();
    d.open("GET", a, !0);
    d.responseType = "arraybuffer";
    d.onload = () => {
      200 == d.status || 0 == d.status && d.response ? b(d.response) : c();
    };
    d.onerror = c;
    d.send(null);
  };
}
var pa = m.print || console.log.bind(console), qa = m.printErr || console.error.bind(console);
Object.assign(m, ja);
ja = null;
m.thisProgram && (ka = m.thisProgram);
var ra;
m.wasmBinary && (ra = m.wasmBinary);
var noExitRuntime = m.noExitRuntime || !0;
"object" != typeof WebAssembly && sa("no native wasm support detected");
var ta, B, ua = !1, D, G, I, va, J, K, wa, xa;
function ya() {
  var a = ta.buffer;
  m.HEAP8 = D = new Int8Array(a);
  m.HEAP16 = I = new Int16Array(a);
  m.HEAP32 = J = new Int32Array(a);
  m.HEAPU8 = G = new Uint8Array(a);
  m.HEAPU16 = va = new Uint16Array(a);
  m.HEAPU32 = K = new Uint32Array(a);
  m.HEAPF32 = wa = new Float32Array(a);
  m.HEAPF64 = xa = new Float64Array(a);
}
var za, Aa = [], Ba = [], Ca = [];
function Da() {
  var a = m.preRun.shift();
  Aa.unshift(a);
}
var Ea = 0, Fa = null, Ga = null;
function sa(a) {
  if (m.onAbort) {
    m.onAbort(a);
  }
  a = "Aborted(" + a + ")";
  qa(a);
  ua = !0;
  a = new WebAssembly.RuntimeError(a + ". Build with -sASSERTIONS for more info.");
  ba(a);
  throw a;
}
function Ha(a) {
  return a.startsWith("data:application/octet-stream;base64,");
}
var Ia;
Ia = "webgl2_advanced.wasm";
if (!Ha(Ia)) {
  var Ja = Ia;
  Ia = m.locateFile ? m.locateFile(Ja, A) : A + Ja;
}
function Ka(a) {
  if (a == Ia && ra) {
    return new Uint8Array(ra);
  }
  if (oa) {
    return oa(a);
  }
  throw "both async and sync fetching of the wasm failed";
}
function La(a) {
  if (!ra && (la || ma)) {
    if ("function" == typeof fetch && !a.startsWith("file://")) {
      return fetch(a, {credentials:"same-origin"}).then(b => {
        if (!b.ok) {
          throw "failed to load wasm binary file at '" + a + "'";
        }
        return b.arrayBuffer();
      }).catch(() => Ka(a));
    }
    if (na) {
      return new Promise((b, c) => {
        na(a, d => b(new Uint8Array(d)), c);
      });
    }
  }
  return Promise.resolve().then(() => Ka(a));
}
function Ma(a, b, c) {
  return La(a).then(d => WebAssembly.instantiate(d, b)).then(d => d).then(c, d => {
    qa("failed to asynchronously prepare wasm: " + d);
    sa(d);
  });
}
function Na(a, b) {
  var c = Ia;
  return ra || "function" != typeof WebAssembly.instantiateStreaming || Ha(c) || c.startsWith("file://") || "function" != typeof fetch ? Ma(c, a, b) : fetch(c, {credentials:"same-origin"}).then(d => WebAssembly.instantiateStreaming(d, a).then(b, function(e) {
    qa("wasm streaming compile failed: " + e);
    qa("falling back to ArrayBuffer instantiation");
    return Ma(c, a, b);
  }));
}
var Oa, Pa, Ta = {505214:(a, b, c, d, e) => {
  if ("undefined" === typeof window || void 0 === (window.AudioContext || window.webkitAudioContext)) {
    return 0;
  }
  if ("undefined" === typeof window.h) {
    window.h = {Da:0};
    window.h.I = {};
    window.h.I.Ba = a;
    window.h.I.capture = b;
    window.h.I.Na = c;
    window.h.ia = {};
    window.h.ia.stopped = d;
    window.h.ia.tb = e;
    let f = window.h;
    f.C = [];
    f.nc = function(g) {
      for (var n = 0; n < f.C.length; ++n) {
        if (null == f.C[n]) {
          return f.C[n] = g, n;
        }
      }
      f.C.push(g);
      return f.C.length - 1;
    };
    f.yb = function(g) {
      for (f.C[g] = null; 0 < f.C.length;) {
        if (null == f.C[f.C.length - 1]) {
          f.C.pop();
        } else {
          break;
        }
      }
    };
    f.Pc = function(g) {
      for (var n = 0; n < f.C.length; ++n) {
        if (f.C[n] == g) {
          return f.yb(n);
        }
      }
    };
    f.va = function(g) {
      return f.C[g];
    };
    f.xb = ["touchend", "click"];
    f.unlock = function() {
      for (var g = 0; g < f.C.length; ++g) {
        var n = f.C[g];
        null != n && null != n.J && n.state === f.ia.tb && n.J.resume().then(() => {
          Qa(n.lb);
        }, p => {
          console.error("Failed to resume audiocontext", p);
        });
      }
      f.xb.map(function(p) {
        document.removeEventListener(p, f.unlock, !0);
      });
    };
    f.xb.map(function(g) {
      document.addEventListener(g, f.unlock, !0);
    });
  }
  window.h.Da += 1;
  return 1;
}, 507392:() => {
  "undefined" !== typeof window.h && (--window.h.Da, 0 === window.h.Da && delete window.h);
}, 507556:() => void 0 !== navigator.mediaDevices && void 0 !== navigator.mediaDevices.getUserMedia, 507660:() => {
  try {
    var a = new (window.AudioContext || window.webkitAudioContext)(), b = a.sampleRate;
    a.close();
    return b;
  } catch (c) {
    return 0;
  }
}, 507831:(a, b, c, d, e, f) => {
  if ("undefined" === typeof window.h) {
    return -1;
  }
  var g = {}, n = {};
  a == window.h.I.Ba && 0 != c && (n.sampleRate = c);
  g.J = new (window.AudioContext || window.webkitAudioContext)(n);
  g.J.suspend();
  g.state = window.h.ia.stopped;
  c = 0;
  a != window.h.I.Ba && (c = b);
  g.Z = g.J.createScriptProcessor(d, c, b);
  g.Z.onaudioprocess = function(p) {
    if (null == g.wa || 0 == g.wa.length) {
      g.wa = new Float32Array(wa.buffer, e, d * b);
    }
    if (a == window.h.I.capture || a == window.h.I.Na) {
      for (var l = 0; l < b; l += 1) {
        for (var t = p.inputBuffer.getChannelData(l), u = g.wa, x = 0; x < d; x += 1) {
          u[x * b + l] = t[x];
        }
      }
      Ra(f, d, e);
    }
    if (a == window.h.I.Ba || a == window.h.I.Na) {
      for (Sa(f, d, e), l = 0; l < p.outputBuffer.numberOfChannels; ++l) {
        for (t = p.outputBuffer.getChannelData(l), u = g.wa, x = 0; x < d; x += 1) {
          t[x] = u[x * b + l];
        }
      }
    } else {
      for (l = 0; l < p.outputBuffer.numberOfChannels; ++l) {
        p.outputBuffer.getChannelData(l).fill(0.0);
      }
    }
  };
  a != window.h.I.capture && a != window.h.I.Na || navigator.mediaDevices.getUserMedia({audio:!0, video:!1}).then(function(p) {
    g.Ea = g.J.createMediaStreamSource(p);
    g.Ea.connect(g.Z);
    g.Z.connect(g.J.destination);
  }).catch(function(p) {
    console.log("Failed to get user media: " + p);
  });
  a == window.h.I.Ba && g.Z.connect(g.J.destination);
  g.lb = f;
  return window.h.nc(g);
}, 510708:a => window.h.va(a).J.sampleRate, 510781:a => {
  a = window.h.va(a);
  void 0 !== a.Z && (a.Z.onaudioprocess = function() {
  }, a.Z.disconnect(), a.Z = void 0);
  void 0 !== a.Ea && (a.Ea.disconnect(), a.Ea = void 0);
  a.J.close();
  a.J = void 0;
  a.lb = void 0;
}, 511181:a => {
  window.h.yb(a);
}, 511231:a => {
  a = window.h.va(a);
  a.J.resume();
  a.state = window.h.ia.tb;
}, 511370:a => {
  a = window.h.va(a);
  a.J.suspend();
  a.state = window.h.ia.stopped;
}}, Ua = a => {
  for (; 0 < a.length;) {
    a.shift()(m);
  }
}, Va = (a, b) => {
  for (var c = 0, d = a.length - 1; 0 <= d; d--) {
    var e = a[d];
    "." === e ? a.splice(d, 1) : ".." === e ? (a.splice(d, 1), c++) : c && (a.splice(d, 1), c--);
  }
  if (b) {
    for (; c; c--) {
      a.unshift("..");
    }
  }
  return a;
}, Wa = a => {
  var b = "/" === a.charAt(0), c = "/" === a.substr(-1);
  (a = Va(a.split("/").filter(d => !!d), !b).join("/")) || b || (a = ".");
  a && c && (a += "/");
  return (b ? "/" : "") + a;
}, Xa = a => {
  var b = /^(\/?|)([\s\S]*?)((?:\.{1,2}|[^\/]+?|)(\.[^.\/]*|))(?:[\/]*)$/.exec(a).slice(1);
  a = b[0];
  b = b[1];
  if (!a && !b) {
    return ".";
  }
  b && (b = b.substr(0, b.length - 1));
  return a + b;
}, Ya = a => {
  if ("/" === a) {
    return "/";
  }
  a = Wa(a);
  a = a.replace(/\/$/, "");
  var b = a.lastIndexOf("/");
  return -1 === b ? a : a.substr(b + 1);
}, Za = () => {
  if ("object" == typeof crypto && "function" == typeof crypto.getRandomValues) {
    return a => crypto.getRandomValues(a);
  }
  sa("initRandomDevice");
}, $a = a => ($a = Za())(a);
function ab() {
  for (var a = "", b = !1, c = arguments.length - 1; -1 <= c && !b; c--) {
    b = 0 <= c ? arguments[c] : "/";
    if ("string" != typeof b) {
      throw new TypeError("Arguments to path.resolve must be strings");
    }
    if (!b) {
      return "";
    }
    a = b + "/" + a;
    b = "/" === b.charAt(0);
  }
  a = Va(a.split("/").filter(d => !!d), !b).join("/");
  return (b ? "/" : "") + a || ".";
}
var bb = "undefined" != typeof TextDecoder ? new TextDecoder("utf8") : void 0, L = (a, b, c) => {
  var d = b + c;
  for (c = b; a[c] && !(c >= d);) {
    ++c;
  }
  if (16 < c - b && a.buffer && bb) {
    return bb.decode(a.subarray(b, c));
  }
  for (d = ""; b < c;) {
    var e = a[b++];
    if (e & 128) {
      var f = a[b++] & 63;
      if (192 == (e & 224)) {
        d += String.fromCharCode((e & 31) << 6 | f);
      } else {
        var g = a[b++] & 63;
        e = 224 == (e & 240) ? (e & 15) << 12 | f << 6 | g : (e & 7) << 18 | f << 12 | g << 6 | a[b++] & 63;
        65536 > e ? d += String.fromCharCode(e) : (e -= 65536, d += String.fromCharCode(55296 | e >> 10, 56320 | e & 1023));
      }
    } else {
      d += String.fromCharCode(e);
    }
  }
  return d;
}, cb = [], db = a => {
  for (var b = 0, c = 0; c < a.length; ++c) {
    var d = a.charCodeAt(c);
    127 >= d ? b++ : 2047 >= d ? b += 2 : 55296 <= d && 57343 >= d ? (b += 4, ++c) : b += 3;
  }
  return b;
}, eb = (a, b, c, d) => {
  if (!(0 < d)) {
    return 0;
  }
  var e = c;
  d = c + d - 1;
  for (var f = 0; f < a.length; ++f) {
    var g = a.charCodeAt(f);
    if (55296 <= g && 57343 >= g) {
      var n = a.charCodeAt(++f);
      g = 65536 + ((g & 1023) << 10) | n & 1023;
    }
    if (127 >= g) {
      if (c >= d) {
        break;
      }
      b[c++] = g;
    } else {
      if (2047 >= g) {
        if (c + 1 >= d) {
          break;
        }
        b[c++] = 192 | g >> 6;
      } else {
        if (65535 >= g) {
          if (c + 2 >= d) {
            break;
          }
          b[c++] = 224 | g >> 12;
        } else {
          if (c + 3 >= d) {
            break;
          }
          b[c++] = 240 | g >> 18;
          b[c++] = 128 | g >> 12 & 63;
        }
        b[c++] = 128 | g >> 6 & 63;
      }
      b[c++] = 128 | g & 63;
    }
  }
  b[c] = 0;
  return c - e;
};
function fb(a, b) {
  var c = Array(db(a) + 1);
  a = eb(a, c, 0, c.length);
  b && (c.length = a);
  return c;
}
var gb = [];
function hb(a, b) {
  gb[a] = {input:[], G:[], V:b};
  ib(a, jb);
}
var jb = {open:function(a) {
  var b = gb[a.node.Ca];
  if (!b) {
    throw new M(43);
  }
  a.s = b;
  a.seekable = !1;
}, close:function(a) {
  a.s.V.ua(a.s);
}, ua:function(a) {
  a.s.V.ua(a.s);
}, read:function(a, b, c, d) {
  if (!a.s || !a.s.V.fb) {
    throw new M(60);
  }
  for (var e = 0, f = 0; f < d; f++) {
    try {
      var g = a.s.V.fb(a.s);
    } catch (n) {
      throw new M(29);
    }
    if (void 0 === g && 0 === e) {
      throw new M(6);
    }
    if (null === g || void 0 === g) {
      break;
    }
    e++;
    b[c + f] = g;
  }
  e && (a.node.timestamp = Date.now());
  return e;
}, write:function(a, b, c, d) {
  if (!a.s || !a.s.V.Qa) {
    throw new M(60);
  }
  try {
    for (var e = 0; e < d; e++) {
      a.s.V.Qa(a.s, b[c + e]);
    }
  } catch (f) {
    throw new M(29);
  }
  d && (a.node.timestamp = Date.now());
  return e;
},}, kb = {fb:function() {
  a: {
    if (!cb.length) {
      var a = null;
      "undefined" != typeof window && "function" == typeof window.prompt ? (a = window.prompt("Input: "), null !== a && (a += "\n")) : "function" == typeof readline && (a = readline(), null !== a && (a += "\n"));
      if (!a) {
        a = null;
        break a;
      }
      cb = fb(a, !0);
    }
    a = cb.shift();
  }
  return a;
}, Qa:function(a, b) {
  null === b || 10 === b ? (pa(L(a.G, 0)), a.G = []) : 0 != b && a.G.push(b);
}, ua:function(a) {
  a.G && 0 < a.G.length && (pa(L(a.G, 0)), a.G = []);
}, Wb:function() {
  return {tc:25856, vc:5, sc:191, uc:35387, rc:[3, 28, 127, 21, 4, 0, 1, 0, 17, 19, 26, 0, 18, 15, 23, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]};
}, Xb:function() {
  return 0;
}, Yb:function() {
  return [24, 80];
},}, lb = {Qa:function(a, b) {
  null === b || 10 === b ? (qa(L(a.G, 0)), a.G = []) : 0 != b && a.G.push(b);
}, ua:function(a) {
  a.G && 0 < a.G.length && (qa(L(a.G, 0)), a.G = []);
},};
function mb(a, b) {
  var c = a.j ? a.j.length : 0;
  c >= b || (b = Math.max(b, c * (1048576 > c ? 2.0 : 1.125) >>> 0), 0 != c && (b = Math.max(b, 256)), c = a.j, a.j = new Uint8Array(b), 0 < a.v && a.j.set(c.subarray(0, a.v), 0));
}
var N = {O:null, U() {
  return N.createNode(null, "/", 16895, 0);
}, createNode(a, b, c, d) {
  if (24576 === (c & 61440) || 4096 === (c & 61440)) {
    throw new M(63);
  }
  N.O || (N.O = {dir:{node:{Y:N.l.Y, P:N.l.P, ma:N.l.ma, za:N.l.za, rb:N.l.rb, wb:N.l.wb, sb:N.l.sb, pb:N.l.pb, Fa:N.l.Fa}, stream:{ba:N.m.ba}}, file:{node:{Y:N.l.Y, P:N.l.P}, stream:{ba:N.m.ba, read:N.m.read, write:N.m.write, ta:N.m.ta, hb:N.m.hb, jb:N.m.jb}}, link:{node:{Y:N.l.Y, P:N.l.P, na:N.l.na}, stream:{}}, Xa:{node:{Y:N.l.Y, P:N.l.P}, stream:nb}});
  c = ob(a, b, c, d);
  16384 === (c.mode & 61440) ? (c.l = N.O.dir.node, c.m = N.O.dir.stream, c.j = {}) : 32768 === (c.mode & 61440) ? (c.l = N.O.file.node, c.m = N.O.file.stream, c.v = 0, c.j = null) : 40960 === (c.mode & 61440) ? (c.l = N.O.link.node, c.m = N.O.link.stream) : 8192 === (c.mode & 61440) && (c.l = N.O.Xa.node, c.m = N.O.Xa.stream);
  c.timestamp = Date.now();
  a && (a.j[b] = c, a.timestamp = c.timestamp);
  return c;
}, Ac(a) {
  return a.j ? a.j.subarray ? a.j.subarray(0, a.v) : new Uint8Array(a.j) : new Uint8Array(0);
}, l:{Y(a) {
  var b = {};
  b.yc = 8192 === (a.mode & 61440) ? a.id : 1;
  b.Cc = a.id;
  b.mode = a.mode;
  b.Kc = 1;
  b.uid = 0;
  b.Bc = 0;
  b.Ca = a.Ca;
  16384 === (a.mode & 61440) ? b.size = 4096 : 32768 === (a.mode & 61440) ? b.size = a.v : 40960 === (a.mode & 61440) ? b.size = a.link.length : b.size = 0;
  b.pc = new Date(a.timestamp);
  b.Ic = new Date(a.timestamp);
  b.wc = new Date(a.timestamp);
  b.Eb = 4096;
  b.qc = Math.ceil(b.size / b.Eb);
  return b;
}, P(a, b) {
  void 0 !== b.mode && (a.mode = b.mode);
  void 0 !== b.timestamp && (a.timestamp = b.timestamp);
  if (void 0 !== b.size && (b = b.size, a.v != b)) {
    if (0 == b) {
      a.j = null, a.v = 0;
    } else {
      var c = a.j;
      a.j = new Uint8Array(b);
      c && a.j.set(c.subarray(0, Math.min(b, a.v)));
      a.v = b;
    }
  }
}, ma() {
  throw pb[44];
}, za(a, b, c, d) {
  return N.createNode(a, b, c, d);
}, rb(a, b, c) {
  if (16384 === (a.mode & 61440)) {
    try {
      var d = qb(b, c);
    } catch (f) {
    }
    if (d) {
      for (var e in d.j) {
        throw new M(55);
      }
    }
  }
  delete a.parent.j[a.name];
  a.parent.timestamp = Date.now();
  a.name = c;
  b.j[c] = a;
  b.timestamp = a.parent.timestamp;
  a.parent = b;
}, wb(a, b) {
  delete a.j[b];
  a.timestamp = Date.now();
}, sb(a, b) {
  var c = qb(a, b), d;
  for (d in c.j) {
    throw new M(55);
  }
  delete a.j[b];
  a.timestamp = Date.now();
}, pb(a) {
  var b = [".", ".."], c;
  for (c in a.j) {
    a.j.hasOwnProperty(c) && b.push(c);
  }
  return b;
}, Fa(a, b, c) {
  a = N.createNode(a, b, 41471, 0);
  a.link = c;
  return a;
}, na(a) {
  if (40960 !== (a.mode & 61440)) {
    throw new M(28);
  }
  return a.link;
},}, m:{read(a, b, c, d, e) {
  var f = a.node.j;
  if (e >= a.node.v) {
    return 0;
  }
  a = Math.min(a.node.v - e, d);
  if (8 < a && f.subarray) {
    b.set(f.subarray(e, e + a), c);
  } else {
    for (d = 0; d < a; d++) {
      b[c + d] = f[e + d];
    }
  }
  return a;
}, write(a, b, c, d, e, f) {
  b.buffer === D.buffer && (f = !1);
  if (!d) {
    return 0;
  }
  a = a.node;
  a.timestamp = Date.now();
  if (b.subarray && (!a.j || a.j.subarray)) {
    if (f) {
      return a.j = b.subarray(c, c + d), a.v = d;
    }
    if (0 === a.v && 0 === e) {
      return a.j = b.slice(c, c + d), a.v = d;
    }
    if (e + d <= a.v) {
      return a.j.set(b.subarray(c, c + d), e), d;
    }
  }
  mb(a, e + d);
  if (a.j.subarray && b.subarray) {
    a.j.set(b.subarray(c, c + d), e);
  } else {
    for (f = 0; f < d; f++) {
      a.j[e + f] = b[c + f];
    }
  }
  a.v = Math.max(a.v, e + d);
  return d;
}, ba(a, b, c) {
  1 === c ? b += a.position : 2 === c && 32768 === (a.node.mode & 61440) && (b += a.node.v);
  if (0 > b) {
    throw new M(28);
  }
  return b;
}, ta(a, b, c) {
  mb(a.node, b + c);
  a.node.v = Math.max(a.node.v, b + c);
}, hb(a, b, c, d, e) {
  if (32768 !== (a.node.mode & 61440)) {
    throw new M(43);
  }
  a = a.node.j;
  if (e & 2 || a.buffer !== D.buffer) {
    if (0 < c || c + b < a.length) {
      a.subarray ? a = a.subarray(c, c + b) : a = Array.prototype.slice.call(a, c, c + b);
    }
    c = !0;
    sa();
    b = void 0;
    if (!b) {
      throw new M(48);
    }
    D.set(a, b);
  } else {
    c = !1, b = a.byteOffset;
  }
  return {o:b, M:c};
}, jb(a, b, c, d) {
  N.m.write(a, b, 0, d, c, !1);
  return 0;
},},};
function sb(a, b) {
  var c = 0;
  a && (c |= 365);
  b && (c |= 146);
  return c;
}
var tb = null, ub = {}, vb = [], wb = 1, xb = null, yb = !0, M = null, pb = {}, Ab = (a, b = {}) => {
  a = ab(a);
  if (!a) {
    return {path:"", node:null};
  }
  b = Object.assign({cb:!0, Sa:0}, b);
  if (8 < b.Sa) {
    throw new M(32);
  }
  a = a.split("/").filter(g => !!g);
  for (var c = tb, d = "/", e = 0; e < a.length; e++) {
    var f = e === a.length - 1;
    if (f && b.parent) {
      break;
    }
    c = qb(c, a[e]);
    d = Wa(d + "/" + a[e]);
    c.Aa && (!f || f && b.cb) && (c = c.Aa.root);
    if (!f || b.bb) {
      for (f = 0; 40960 === (c.mode & 61440);) {
        if (c = zb(d), d = ab(Xa(d), c), c = Ab(d, {Sa:b.Sa + 1}).node, 40 < f++) {
          throw new M(32);
        }
      }
    }
  }
  return {path:d, node:c};
}, Bb = a => {
  for (var b;;) {
    if (a === a.parent) {
      return a = a.U.ib, b ? "/" !== a[a.length - 1] ? `${a}/${b}` : a + b : a;
    }
    b = b ? `${a.name}/${b}` : a.name;
    a = a.parent;
  }
}, Cb = (a, b) => {
  for (var c = 0, d = 0; d < b.length; d++) {
    c = (c << 5) - c + b.charCodeAt(d) | 0;
  }
  return (a + c >>> 0) % xb.length;
}, qb = (a, b) => {
  var c;
  if (c = (c = Db(a, "x")) ? c : a.l.ma ? 0 : 2) {
    throw new M(c, a);
  }
  for (c = xb[Cb(a.id, b)]; c; c = c.ac) {
    var d = c.name;
    if (c.parent.id === a.id && d === b) {
      return c;
    }
  }
  return a.l.ma(a, b);
}, ob = (a, b, c, d) => {
  a = new Eb(a, b, c, d);
  b = Cb(a.parent.id, a.name);
  a.ac = xb[b];
  return xb[b] = a;
}, Fb = a => {
  var b = ["r", "w", "rw"][a & 3];
  a & 512 && (b += "w");
  return b;
}, Db = (a, b) => {
  if (yb) {
    return 0;
  }
  if (!b.includes("r") || a.mode & 292) {
    if (b.includes("w") && !(a.mode & 146) || b.includes("x") && !(a.mode & 73)) {
      return 2;
    }
  } else {
    return 2;
  }
  return 0;
}, Gb = (a, b) => {
  try {
    return qb(a, b), 20;
  } catch (c) {
  }
  return Db(a, "wx");
}, Hb = () => {
  for (var a = 0; 4096 >= a; a++) {
    if (!vb[a]) {
      return a;
    }
  }
  throw new M(33);
}, Ib = a => {
  a = vb[a];
  if (!a) {
    throw new M(8);
  }
  return a;
}, Kb = (a, b = -1) => {
  Jb || (Jb = function() {
    this.h = {};
  }, Jb.prototype = {}, Object.defineProperties(Jb.prototype, {object:{get() {
    return this.node;
  }, set(c) {
    this.node = c;
  }}, flags:{get() {
    return this.h.flags;
  }, set(c) {
    this.h.flags = c;
  },}, position:{get() {
    return this.h.position;
  }, set(c) {
    this.h.position = c;
  },},}));
  a = Object.assign(new Jb(), a);
  -1 == b && (b = Hb());
  a.X = b;
  return vb[b] = a;
}, nb = {open:a => {
  a.m = ub[a.node.Ca].m;
  a.m.open && a.m.open(a);
}, ba:() => {
  throw new M(70);
},}, ib = (a, b) => {
  ub[a] = {m:b};
}, Lb = (a, b) => {
  var c = "/" === b, d = !b;
  if (c && tb) {
    throw new M(10);
  }
  if (!c && !d) {
    var e = Ab(b, {cb:!1});
    b = e.path;
    e = e.node;
    if (e.Aa) {
      throw new M(10);
    }
    if (16384 !== (e.mode & 61440)) {
      throw new M(54);
    }
  }
  b = {type:a, Mc:{}, ib:b, $b:[]};
  a = a.U(b);
  a.U = b;
  b.root = a;
  c ? tb = a : e && (e.Aa = b, e.U && e.U.$b.push(b));
}, O = (a, b, c) => {
  var d = Ab(a, {parent:!0}).node;
  a = Ya(a);
  if (!a || "." === a || ".." === a) {
    throw new M(28);
  }
  var e = Gb(d, a);
  if (e) {
    throw new M(e);
  }
  if (!d.l.za) {
    throw new M(63);
  }
  return d.l.za(d, a, b, c);
}, Mb = (a, b, c) => {
  "undefined" == typeof c && (c = b, b = 438);
  O(a, b | 8192, c);
}, Nb = (a, b) => {
  if (!ab(a)) {
    throw new M(44);
  }
  var c = Ab(b, {parent:!0}).node;
  if (!c) {
    throw new M(44);
  }
  b = Ya(b);
  var d = Gb(c, b);
  if (d) {
    throw new M(d);
  }
  if (!c.l.Fa) {
    throw new M(63);
  }
  c.l.Fa(c, b, a);
}, zb = a => {
  a = Ab(a).node;
  if (!a) {
    throw new M(44);
  }
  if (!a.l.na) {
    throw new M(28);
  }
  return ab(Bb(a.parent), a.l.na(a));
}, Pb = (a, b, c) => {
  if ("" === a) {
    throw new M(44);
  }
  if ("string" == typeof b) {
    var d = {r:0, "r+":2, w:577, "w+":578, a:1089, "a+":1090,}[b];
    if ("undefined" == typeof d) {
      throw Error(`Unknown file open mode: ${b}`);
    }
    b = d;
  }
  c = b & 64 ? ("undefined" == typeof c ? 438 : c) & 4095 | 32768 : 0;
  if ("object" == typeof a) {
    var e = a;
  } else {
    a = Wa(a);
    try {
      e = Ab(a, {bb:!(b & 131072)}).node;
    } catch (f) {
    }
  }
  d = !1;
  if (b & 64) {
    if (e) {
      if (b & 128) {
        throw new M(20);
      }
    } else {
      e = O(a, c, 0), d = !0;
    }
  }
  if (!e) {
    throw new M(44);
  }
  8192 === (e.mode & 61440) && (b &= -513);
  if (b & 65536 && 16384 !== (e.mode & 61440)) {
    throw new M(54);
  }
  if (!d && (c = e ? 40960 === (e.mode & 61440) ? 32 : 16384 === (e.mode & 61440) && ("r" !== Fb(b) || b & 512) ? 31 : Db(e, Fb(b)) : 44)) {
    throw new M(c);
  }
  if (b & 512 && !d) {
    c = e;
    c = "string" == typeof c ? Ab(c, {bb:!0}).node : c;
    if (!c.l.P) {
      throw new M(63);
    }
    if (16384 === (c.mode & 61440)) {
      throw new M(31);
    }
    if (32768 !== (c.mode & 61440)) {
      throw new M(28);
    }
    if (d = Db(c, "w")) {
      throw new M(d);
    }
    c.l.P(c, {size:0, timestamp:Date.now()});
  }
  b &= -131713;
  e = Kb({node:e, path:Bb(e), flags:b, seekable:!0, position:0, m:e.m, oc:[], error:!1});
  e.m.open && e.m.open(e);
  !m.logReadFiles || b & 1 || (Ob || (Ob = {}), a in Ob || (Ob[a] = 1));
  return e;
}, Qb = (a, b, c) => {
  if (null === a.X) {
    throw new M(8);
  }
  if (!a.seekable || !a.m.ba) {
    throw new M(70);
  }
  if (0 != c && 1 != c && 2 != c) {
    throw new M(28);
  }
  a.position = a.m.ba(a, b, c);
  a.oc = [];
}, Rb = () => {
  M || (M = function(a, b) {
    this.name = "ErrnoError";
    this.node = b;
    this.dc = function(c) {
      this.aa = c;
    };
    this.dc(a);
    this.message = "FS error";
  }, M.prototype = Error(), M.prototype.constructor = M, [44].forEach(a => {
    pb[a] = new M(a);
    pb[a].stack = "<generic error, no stack>";
  }));
}, Sb, Ub = (a, b, c) => {
  a = Wa("/dev/" + a);
  var d = sb(!!b, !!c);
  Tb || (Tb = 64);
  var e = Tb++ << 8 | 0;
  ib(e, {open:f => {
    f.seekable = !1;
  }, close:() => {
    c && c.buffer && c.buffer.length && c(10);
  }, read:(f, g, n, p) => {
    for (var l = 0, t = 0; t < p; t++) {
      try {
        var u = b();
      } catch (x) {
        throw new M(29);
      }
      if (void 0 === u && 0 === l) {
        throw new M(6);
      }
      if (null === u || void 0 === u) {
        break;
      }
      l++;
      g[n + t] = u;
    }
    l && (f.node.timestamp = Date.now());
    return l;
  }, write:(f, g, n, p) => {
    for (var l = 0; l < p; l++) {
      try {
        c(g[n + l]);
      } catch (t) {
        throw new M(29);
      }
    }
    p && (f.node.timestamp = Date.now());
    return l;
  }});
  Mb(a, d, e);
}, Tb, Vb = {}, Jb, Ob, Wb = void 0;
function Xb() {
  Wb += 4;
  return J[Wb - 4 >> 2];
}
function Yb(a) {
  if (void 0 === a) {
    return "_unknown";
  }
  a = a.replace(/[^a-zA-Z0-9_]/g, "$");
  var b = a.charCodeAt(0);
  return 48 <= b && 57 >= b ? `_${a}` : a;
}
function Zb(a, b) {
  a = Yb(a);
  return {[a]:function() {
    return b.apply(this, arguments);
  }}[a];
}
function $b() {
  this.M = [void 0];
  this.eb = [];
}
var P = new $b(), ac = void 0;
function Q(a) {
  throw new ac(a);
}
var R = a => {
  a || Q("Cannot use deleted val. handle = " + a);
  return P.get(a).value;
}, bc = a => {
  switch(a) {
    case void 0:
      return 1;
    case null:
      return 2;
    case !0:
      return 3;
    case !1:
      return 4;
    default:
      return P.ta({qb:1, value:a});
  }
};
function cc(a) {
  var b = Error, c = Zb(a, function(d) {
    this.name = a;
    this.message = d;
    d = Error(d).stack;
    void 0 !== d && (this.stack = this.toString() + "\n" + d.replace(/^Error(:[^\n]*)?\n/, ""));
  });
  c.prototype = Object.create(b.prototype);
  c.prototype.constructor = c;
  c.prototype.toString = function() {
    return void 0 === this.message ? this.name : `${this.name}: ${this.message}`;
  };
  return c;
}
var dc = void 0, ec = void 0;
function S(a) {
  for (var b = ""; G[a];) {
    b += ec[G[a++]];
  }
  return b;
}
var fc = [];
function gc() {
  for (; fc.length;) {
    var a = fc.pop();
    a.g.ha = !1;
    a["delete"]();
  }
}
var hc = void 0, ic = {};
function jc(a, b) {
  for (void 0 === b && Q("ptr should not be undefined"); a.A;) {
    b = a.pa(b), a = a.A;
  }
  return b;
}
var kc = {};
function lc(a) {
  a = mc(a);
  var b = S(a);
  nc(a);
  return b;
}
function oc(a, b) {
  var c = kc[a];
  void 0 === c && Q(b + " has unknown type " + lc(a));
  return c;
}
function pc() {
}
var qc = !1;
function rc(a) {
  --a.count.value;
  0 === a.count.value && (a.H ? a.L.W(a.H) : a.u.i.W(a.o));
}
function sc(a, b, c) {
  if (b === c) {
    return a;
  }
  if (void 0 === c.A) {
    return null;
  }
  a = sc(a, b, c.A);
  return null === a ? null : c.Ib(a);
}
var tc = {};
function uc(a, b) {
  b = jc(a, b);
  return ic[b];
}
var vc = void 0;
function wc(a) {
  throw new vc(a);
}
function xc(a, b) {
  b.u && b.o || wc("makeClassHandle requires ptr and ptrType");
  !!b.L !== !!b.H && wc("Both smartPtrType and smartPtr must be specified");
  b.count = {value:1};
  return yc(Object.create(a, {g:{value:b,},}));
}
function yc(a) {
  if ("undefined" === typeof FinalizationRegistry) {
    return yc = b => b, a;
  }
  qc = new FinalizationRegistry(b => {
    rc(b.g);
  });
  yc = b => {
    var c = b.g;
    c.H && qc.register(b, {g:c}, b);
    return b;
  };
  pc = b => {
    qc.unregister(b);
  };
  return yc(a);
}
var zc = {};
function Ac(a) {
  for (; a.length;) {
    var b = a.pop();
    a.pop()(b);
  }
}
function Bc(a) {
  return this.fromWireType(J[a >> 2]);
}
var Cc = {}, Dc = {};
function T(a, b, c) {
  function d(n) {
    n = c(n);
    n.length !== a.length && wc("Mismatched type converter count");
    for (var p = 0; p < a.length; ++p) {
      U(a[p], n[p]);
    }
  }
  a.forEach(function(n) {
    Dc[n] = b;
  });
  var e = Array(b.length), f = [], g = 0;
  b.forEach((n, p) => {
    kc.hasOwnProperty(n) ? e[p] = kc[n] : (f.push(n), Cc.hasOwnProperty(n) || (Cc[n] = []), Cc[n].push(() => {
      e[p] = kc[n];
      ++g;
      g === f.length && d(e);
    }));
  });
  0 === f.length && d(e);
}
function Ec(a) {
  switch(a) {
    case 1:
      return 0;
    case 2:
      return 1;
    case 4:
      return 2;
    case 8:
      return 3;
    default:
      throw new TypeError(`Unknown type size: ${a}`);
  }
}
function Fc(a, b, c = {}) {
  var d = b.name;
  a || Q(`type "${d}" must have a positive integer typeid pointer`);
  if (kc.hasOwnProperty(a)) {
    if (c.Tb) {
      return;
    }
    Q(`Cannot register type '${d}' twice`);
  }
  kc[a] = b;
  delete Dc[a];
  Cc.hasOwnProperty(a) && (b = Cc[a], delete Cc[a], b.forEach(e => e()));
}
function U(a, b, c = {}) {
  if (!("argPackAdvance" in b)) {
    throw new TypeError("registerType registeredInstance requires argPackAdvance");
  }
  Fc(a, b, c);
}
function Gc(a) {
  Q(a.g.u.i.name + " instance already deleted");
}
function Hc() {
}
function Ic(a, b, c) {
  if (void 0 === a[b].B) {
    var d = a[b];
    a[b] = function() {
      a[b].B.hasOwnProperty(arguments.length) || Q(`Function '${c}' called with an invalid number of arguments (${arguments.length}) - expects one of (${a[b].B})!`);
      return a[b].B[arguments.length].apply(this, arguments);
    };
    a[b].B = [];
    a[b].B[d.ga] = d;
  }
}
function Jc(a, b, c) {
  m.hasOwnProperty(a) ? ((void 0 === c || void 0 !== m[a].B && void 0 !== m[a].B[c]) && Q(`Cannot register public name '${a}' twice`), Ic(m, a, a), m.hasOwnProperty(c) && Q(`Cannot register multiple overloads of a function with the same number of arguments (${c})!`), m[a].B[c] = b) : (m[a] = b, void 0 !== c && (m[a].Lc = c));
}
function Kc(a, b, c, d, e, f, g, n) {
  this.name = a;
  this.constructor = b;
  this.N = c;
  this.W = d;
  this.A = e;
  this.Nb = f;
  this.pa = g;
  this.Ib = n;
  this.mb = [];
}
function Lc(a, b, c) {
  for (; b !== c;) {
    b.pa || Q(`Expected null or instance of ${c.name}, got an instance of ${b.name}`), a = b.pa(a), b = b.A;
  }
  return a;
}
function Mc(a, b) {
  if (null === b) {
    return this.Pa && Q(`null is not a valid ${this.name}`), 0;
  }
  b.g || Q(`Cannot pass "${Nc(b)}" as a ${this.name}`);
  b.g.o || Q(`Cannot pass deleted object as a pointer of type ${this.name}`);
  return Lc(b.g.o, b.g.u.i, this.i);
}
function Oc(a, b) {
  if (null === b) {
    this.Pa && Q(`null is not a valid ${this.name}`);
    if (this.ya) {
      var c = this.Ra();
      null !== a && a.push(this.W, c);
      return c;
    }
    return 0;
  }
  b.g || Q(`Cannot pass "${Nc(b)}" as a ${this.name}`);
  b.g.o || Q(`Cannot pass deleted object as a pointer of type ${this.name}`);
  !this.xa && b.g.u.xa && Q(`Cannot convert argument of type ${b.g.L ? b.g.L.name : b.g.u.name} to parameter type ${this.name}`);
  c = Lc(b.g.o, b.g.u.i, this.i);
  if (this.ya) {
    switch(void 0 === b.g.H && Q("Passing raw pointer to smart pointer is illegal"), this.ic) {
      case 0:
        b.g.L === this ? c = b.g.H : Q(`Cannot convert argument of type ${b.g.L ? b.g.L.name : b.g.u.name} to parameter type ${this.name}`);
        break;
      case 1:
        c = b.g.H;
        break;
      case 2:
        if (b.g.L === this) {
          c = b.g.H;
        } else {
          var d = b.clone();
          c = this.cc(c, bc(function() {
            d["delete"]();
          }));
          null !== a && a.push(this.W, c);
        }
        break;
      default:
        Q("Unsupporting sharing policy");
    }
  }
  return c;
}
function Pc(a, b) {
  if (null === b) {
    return this.Pa && Q(`null is not a valid ${this.name}`), 0;
  }
  b.g || Q(`Cannot pass "${Nc(b)}" as a ${this.name}`);
  b.g.o || Q(`Cannot pass deleted object as a pointer of type ${this.name}`);
  b.g.u.xa && Q(`Cannot convert argument of type ${b.g.u.name} to parameter type ${this.name}`);
  return Lc(b.g.o, b.g.u.i, this.i);
}
function Qc(a, b, c, d) {
  this.name = a;
  this.i = b;
  this.Pa = c;
  this.xa = d;
  this.ya = !1;
  this.W = this.cc = this.Ra = this.ob = this.ic = this.bc = void 0;
  void 0 !== b.A ? this.toWireType = Oc : (this.toWireType = d ? Mc : Pc, this.K = null);
}
function Rc(a, b, c) {
  m.hasOwnProperty(a) || wc("Replacing nonexistant public symbol");
  void 0 !== m[a].B && void 0 !== c ? m[a].B[c] = b : (m[a] = b, m[a].ga = c);
}
var Sc = [], Tc = a => {
  var b = Sc[a];
  b || (a >= Sc.length && (Sc.length = a + 1), Sc[a] = b = za.get(a));
  return b;
}, Uc = (a, b) => {
  var c = [];
  return function() {
    c.length = 0;
    Object.assign(c, arguments);
    if (a.includes("j")) {
      var d = m["dynCall_" + a];
      d = c && c.length ? d.apply(null, [b].concat(c)) : d.call(null, b);
    } else {
      d = Tc(b).apply(null, c);
    }
    return d;
  };
};
function V(a, b) {
  a = S(a);
  var c = a.includes("j") ? Uc(a, b) : Tc(b);
  "function" != typeof c && Q(`unknown function pointer with signature ${a}: ${b}`);
  return c;
}
var Vc = void 0;
function Wc(a, b) {
  function c(f) {
    e[f] || kc[f] || (Dc[f] ? Dc[f].forEach(c) : (d.push(f), e[f] = !0));
  }
  var d = [], e = {};
  b.forEach(c);
  throw new Vc(`${a}: ` + d.map(lc).join([", "]));
}
function Xc(a, b, c, d, e) {
  var f = b.length;
  2 > f && Q("argTypes array size mismatch! Must at least get return value and 'this' types!");
  var g = null !== b[1] && null !== c, n = !1;
  for (c = 1; c < b.length; ++c) {
    if (null !== b[c] && void 0 === b[c].K) {
      n = !0;
      break;
    }
  }
  var p = "void" !== b[0].name, l = f - 2, t = Array(l), u = [], x = [];
  return function() {
    arguments.length !== l && Q(`function ${a} called with ${arguments.length} arguments, expected ${l} args!`);
    x.length = 0;
    u.length = g ? 2 : 1;
    u[0] = e;
    if (g) {
      var k = b[1].toWireType(x, this);
      u[1] = k;
    }
    for (var q = 0; q < l; ++q) {
      t[q] = b[q + 2].toWireType(x, arguments[q]), u.push(t[q]);
    }
    q = d.apply(null, u);
    if (n) {
      Ac(x);
    } else {
      for (var v = g ? 1 : 2; v < b.length; v++) {
        var y = 1 === v ? k : t[v - 2];
        null !== b[v].K && b[v].K(y);
      }
    }
    k = p ? b[0].fromWireType(q) : void 0;
    return k;
  };
}
function Yc(a, b) {
  for (var c = [], d = 0; d < a; d++) {
    c.push(K[b + 4 * d >> 2]);
  }
  return c;
}
function Zc(a, b, c) {
  a instanceof Object || Q(`${c} with invalid "this": ${a}`);
  a instanceof b.i.constructor || Q(`${c} incompatible with "this" of type ${a.constructor.name}`);
  a.g.o || Q(`cannot call emscripten binding method ${c} on deleted object`);
  return Lc(a.g.o, a.g.u.i, b.i);
}
function $c(a) {
  a >= P.h && 0 === --P.get(a).qb && P.Sb(a);
}
function ad(a, b, c) {
  switch(b) {
    case 0:
      return function(d) {
        return this.fromWireType((c ? D : G)[d]);
      };
    case 1:
      return function(d) {
        return this.fromWireType((c ? I : va)[d >> 1]);
      };
    case 2:
      return function(d) {
        return this.fromWireType((c ? J : K)[d >> 2]);
      };
    default:
      throw new TypeError("Unknown integer type: " + a);
  }
}
function Nc(a) {
  if (null === a) {
    return "null";
  }
  var b = typeof a;
  return "object" === b || "array" === b || "function" === b ? a.toString() : "" + a;
}
function bd(a, b) {
  switch(b) {
    case 2:
      return function(c) {
        return this.fromWireType(wa[c >> 2]);
      };
    case 3:
      return function(c) {
        return this.fromWireType(xa[c >> 3]);
      };
    default:
      throw new TypeError("Unknown float type: " + a);
  }
}
function cd(a, b, c) {
  switch(b) {
    case 0:
      return c ? function(d) {
        return D[d];
      } : function(d) {
        return G[d];
      };
    case 1:
      return c ? function(d) {
        return I[d >> 1];
      } : function(d) {
        return va[d >> 1];
      };
    case 2:
      return c ? function(d) {
        return J[d >> 2];
      } : function(d) {
        return K[d >> 2];
      };
    default:
      throw new TypeError("Unknown integer type: " + a);
  }
}
var dd = "undefined" != typeof TextDecoder ? new TextDecoder("utf-16le") : void 0, ed = (a, b) => {
  var c = a >> 1;
  for (var d = c + b / 2; !(c >= d) && va[c];) {
    ++c;
  }
  c <<= 1;
  if (32 < c - a && dd) {
    return dd.decode(G.subarray(a, c));
  }
  c = "";
  for (d = 0; !(d >= b / 2); ++d) {
    var e = I[a + 2 * d >> 1];
    if (0 == e) {
      break;
    }
    c += String.fromCharCode(e);
  }
  return c;
}, fd = (a, b, c) => {
  void 0 === c && (c = 2147483647);
  if (2 > c) {
    return 0;
  }
  c -= 2;
  var d = b;
  c = c < 2 * a.length ? c / 2 : a.length;
  for (var e = 0; e < c; ++e) {
    I[b >> 1] = a.charCodeAt(e), b += 2;
  }
  I[b >> 1] = 0;
  return b - d;
}, gd = a => 2 * a.length, hd = (a, b) => {
  for (var c = 0, d = ""; !(c >= b / 4);) {
    var e = J[a + 4 * c >> 2];
    if (0 == e) {
      break;
    }
    ++c;
    65536 <= e ? (e -= 65536, d += String.fromCharCode(55296 | e >> 10, 56320 | e & 1023)) : d += String.fromCharCode(e);
  }
  return d;
}, jd = (a, b, c) => {
  void 0 === c && (c = 2147483647);
  if (4 > c) {
    return 0;
  }
  var d = b;
  c = d + c - 4;
  for (var e = 0; e < a.length; ++e) {
    var f = a.charCodeAt(e);
    if (55296 <= f && 57343 >= f) {
      var g = a.charCodeAt(++e);
      f = 65536 + ((f & 1023) << 10) | g & 1023;
    }
    J[b >> 2] = f;
    b += 4;
    if (b + 4 > c) {
      break;
    }
  }
  J[b >> 2] = 0;
  return b - d;
}, kd = a => {
  for (var b = 0, c = 0; c < a.length; ++c) {
    var d = a.charCodeAt(c);
    55296 <= d && 57343 >= d && ++c;
    b += 4;
  }
  return b;
}, ld = {};
function md(a) {
  var b = ld[a];
  return void 0 === b ? S(a) : b;
}
var nd = [];
function od(a) {
  var b = nd.length;
  nd.push(a);
  return b;
}
function pd(a, b) {
  for (var c = Array(a), d = 0; d < a; ++d) {
    c[d] = oc(K[b + 4 * d >> 2], "parameter " + d);
  }
  return c;
}
var qd = [], rd = [];
function sd(a) {
  a.zc = a.getExtension("WEBGL_draw_instanced_base_vertex_base_instance");
}
function td(a) {
  a.Hc = a.getExtension("WEBGL_multi_draw_instanced_base_vertex_base_instance");
}
var ud = 1, vd = [], W = [], wd = [], xd = [], yd = [], zd = [], Ad = [], w = [], Bd = {};
function X(a) {
  Cd || (Cd = a);
}
function Dd(a) {
  for (var b = ud++, c = a.length; c < b; c++) {
    a[c] = null;
  }
  return b;
}
function ha(a, b) {
  var c = Dd(w), d = {handle:c, attributes:b, version:b.Dc, D:a};
  a.canvas && (a.canvas.zb = d);
  w[c] = d;
  ("undefined" == typeof b.Jb || b.Jb) && Ed(d);
  return c;
}
function ia(a) {
  r = w[a];
  m.xc = Y = r && r.D;
  return !(a && !Y);
}
function Ed(a) {
  a || (a = r);
  if (!a.Ub) {
    a.Ub = !0;
    var b = a.D;
    sd(b);
    td(b);
    2 <= a.version && (b.$a = b.getExtension("EXT_disjoint_timer_query_webgl2"));
    if (2 > a.version || !b.$a) {
      b.$a = b.getExtension("EXT_disjoint_timer_query");
    }
    Fd(b);
    (b.getSupportedExtensions() || []).forEach(function(c) {
      c.includes("lose_context") || c.includes("debug") || b.getExtension(c);
    });
  }
}
var Cd, r;
function Fd(a) {
  a.Jc = a.getExtension("WEBGL_multi_draw");
}
var Gd = {}, Id = () => {
  if (!Hd) {
    var a = {USER:"web_user", LOGNAME:"web_user", PATH:"/", PWD:"/", HOME:"/home/web_user", LANG:("object" == typeof navigator && navigator.languages && navigator.languages[0] || "C").replace("-", "_") + ".UTF-8", _:ka || "./this.program"}, b;
    for (b in Gd) {
      void 0 === Gd[b] ? delete a[b] : a[b] = Gd[b];
    }
    var c = [];
    for (b in a) {
      c.push(`${b}=${a[b]}`);
    }
    Hd = c;
  }
  return Hd;
}, Hd, Jd = [];
function Kd(a, b, c, d) {
  for (var e = 0; e < a; e++) {
    var f = Y[c](), g = f && Dd(d);
    f ? (f.name = g, d[g] = f) : X(1282);
    J[b + 4 * e >> 2] = g;
  }
}
function Ld(a, b) {
  if (b) {
    var c = void 0;
    switch(a) {
      case 36346:
        c = 1;
        break;
      case 36344:
        return;
      case 34814:
      case 36345:
        c = 0;
        break;
      case 34466:
        var d = Y.getParameter(34467);
        c = d ? d.length : 0;
        break;
      case 33309:
        if (2 > r.version) {
          X(1282);
          return;
        }
        c = 2 * (Y.getSupportedExtensions() || []).length;
        break;
      case 33307:
      case 33308:
        if (2 > r.version) {
          X(1280);
          return;
        }
        c = 33307 == a ? 3 : 0;
    }
    if (void 0 === c) {
      switch(d = Y.getParameter(a), typeof d) {
        case "number":
          c = d;
          break;
        case "boolean":
          c = d ? 1 : 0;
          break;
        case "string":
          X(1280);
          return;
        case "object":
          if (null === d) {
            switch(a) {
              case 34964:
              case 35725:
              case 34965:
              case 36006:
              case 36007:
              case 32873:
              case 34229:
              case 36662:
              case 36663:
              case 35053:
              case 35055:
              case 36010:
              case 35097:
              case 35869:
              case 32874:
              case 36389:
              case 35983:
              case 35368:
              case 34068:
                c = 0;
                break;
              default:
                X(1280);
                return;
            }
          } else {
            if (d instanceof Float32Array || d instanceof Uint32Array || d instanceof Int32Array || d instanceof Array) {
              for (a = 0; a < d.length; ++a) {
                J[b + 4 * a >> 2] = d[a];
              }
              return;
            }
            try {
              c = d.name | 0;
            } catch (e) {
              X(1280);
              qa("GL_INVALID_ENUM in glGet0v: Unknown object returned from WebGL getParameter(" + a + ")! (error: " + e + ")");
              return;
            }
          }
          break;
        default:
          X(1280);
          qa("GL_INVALID_ENUM in glGet0v: Native code calling glGet0v(" + a + ") and it returns " + d + " of type " + typeof d + "!");
          return;
      }
    }
    J[b >> 2] = c;
  } else {
    X(1281);
  }
}
var Nd = a => {
  var b = db(a) + 1, c = Md(b);
  c && eb(a, G, c, b);
  return c;
};
function Od(a) {
  return "]" == a.slice(-1) && a.lastIndexOf("[");
}
var Pd = a => 0 === a % 4 && (0 !== a % 100 || 0 === a % 400), Qd = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], Rd = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], Sd = (a, b, c, d) => {
  function e(k, q, v) {
    for (k = "number" == typeof k ? k.toString() : k || ""; k.length < q;) {
      k = v[0] + k;
    }
    return k;
  }
  function f(k, q) {
    return e(k, q, "0");
  }
  function g(k, q) {
    function v(C) {
      return 0 > C ? -1 : 0 < C ? 1 : 0;
    }
    var y;
    0 === (y = v(k.getFullYear() - q.getFullYear())) && 0 === (y = v(k.getMonth() - q.getMonth())) && (y = v(k.getDate() - q.getDate()));
    return y;
  }
  function n(k) {
    switch(k.getDay()) {
      case 0:
        return new Date(k.getFullYear() - 1, 11, 29);
      case 1:
        return k;
      case 2:
        return new Date(k.getFullYear(), 0, 3);
      case 3:
        return new Date(k.getFullYear(), 0, 2);
      case 4:
        return new Date(k.getFullYear(), 0, 1);
      case 5:
        return new Date(k.getFullYear() - 1, 11, 31);
      case 6:
        return new Date(k.getFullYear() - 1, 11, 30);
    }
  }
  function p(k) {
    var q = k.ca;
    for (k = new Date((new Date(k.da + 1900, 0, 1)).getTime()); 0 < q;) {
      var v = k.getMonth(), y = (Pd(k.getFullYear()) ? Qd : Rd)[v];
      if (q > y - k.getDate()) {
        q -= y - k.getDate() + 1, k.setDate(1), 11 > v ? k.setMonth(v + 1) : (k.setMonth(0), k.setFullYear(k.getFullYear() + 1));
      } else {
        k.setDate(k.getDate() + q);
        break;
      }
    }
    v = new Date(k.getFullYear() + 1, 0, 4);
    q = n(new Date(k.getFullYear(), 0, 4));
    v = n(v);
    return 0 >= g(q, k) ? 0 >= g(v, k) ? k.getFullYear() + 1 : k.getFullYear() : k.getFullYear() - 1;
  }
  var l = J[d + 40 >> 2];
  d = {lc:J[d >> 2], kc:J[d + 4 >> 2], Ga:J[d + 8 >> 2], Ta:J[d + 12 >> 2], Ha:J[d + 16 >> 2], da:J[d + 20 >> 2], R:J[d + 24 >> 2], ca:J[d + 28 >> 2], Oc:J[d + 32 >> 2], jc:J[d + 36 >> 2], mc:l ? l ? L(G, l) : "" : ""};
  c = c ? L(G, c) : "";
  l = {"%c":"%a %b %d %H:%M:%S %Y", "%D":"%m/%d/%y", "%F":"%Y-%m-%d", "%h":"%b", "%r":"%I:%M:%S %p", "%R":"%H:%M", "%T":"%H:%M:%S", "%x":"%m/%d/%y", "%X":"%H:%M:%S", "%Ec":"%c", "%EC":"%C", "%Ex":"%m/%d/%y", "%EX":"%H:%M:%S", "%Ey":"%y", "%EY":"%Y", "%Od":"%d", "%Oe":"%e", "%OH":"%H", "%OI":"%I", "%Om":"%m", "%OM":"%M", "%OS":"%S", "%Ou":"%u", "%OU":"%U", "%OV":"%V", "%Ow":"%w", "%OW":"%W", "%Oy":"%y",};
  for (var t in l) {
    c = c.replace(new RegExp(t, "g"), l[t]);
  }
  var u = "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "), x = "January February March April May June July August September October November December".split(" ");
  l = {"%a":k => u[k.R].substring(0, 3), "%A":k => u[k.R], "%b":k => x[k.Ha].substring(0, 3), "%B":k => x[k.Ha], "%C":k => f((k.da + 1900) / 100 | 0, 2), "%d":k => f(k.Ta, 2), "%e":k => e(k.Ta, 2, " "), "%g":k => p(k).toString().substring(2), "%G":k => p(k), "%H":k => f(k.Ga, 2), "%I":k => {
    k = k.Ga;
    0 == k ? k = 12 : 12 < k && (k -= 12);
    return f(k, 2);
  }, "%j":k => {
    for (var q = 0, v = 0; v <= k.Ha - 1; q += (Pd(k.da + 1900) ? Qd : Rd)[v++]) {
    }
    return f(k.Ta + q, 3);
  }, "%m":k => f(k.Ha + 1, 2), "%M":k => f(k.kc, 2), "%n":() => "\n", "%p":k => 0 <= k.Ga && 12 > k.Ga ? "AM" : "PM", "%S":k => f(k.lc, 2), "%t":() => "\t", "%u":k => k.R || 7, "%U":k => f(Math.floor((k.ca + 7 - k.R) / 7), 2), "%V":k => {
    var q = Math.floor((k.ca + 7 - (k.R + 6) % 7) / 7);
    2 >= (k.R + 371 - k.ca - 2) % 7 && q++;
    if (q) {
      53 == q && (v = (k.R + 371 - k.ca) % 7, 4 == v || 3 == v && Pd(k.da) || (q = 1));
    } else {
      q = 52;
      var v = (k.R + 7 - k.ca - 1) % 7;
      (4 == v || 5 == v && Pd(k.da % 400 - 1)) && q++;
    }
    return f(q, 2);
  }, "%w":k => k.R, "%W":k => f(Math.floor((k.ca + 7 - (k.R + 6) % 7) / 7), 2), "%y":k => (k.da + 1900).toString().substring(2), "%Y":k => k.da + 1900, "%z":k => {
    k = k.jc;
    var q = 0 <= k;
    k = Math.abs(k) / 60;
    return (q ? "+" : "-") + String("0000" + (k / 60 * 100 + k % 60)).slice(-4);
  }, "%Z":k => k.mc, "%%":() => "%"};
  c = c.replace(/%%/g, "\x00\x00");
  for (t in l) {
    c.includes(t) && (c = c.replace(new RegExp(t, "g"), l[t](d)));
  }
  c = c.replace(/\0\0/g, "%");
  t = fb(c, !1);
  if (t.length > b) {
    return 0;
  }
  D.set(t, a);
  return t.length - 1;
};
function Eb(a, b, c, d) {
  a || (a = this);
  this.parent = a;
  this.U = a.U;
  this.Aa = null;
  this.id = wb++;
  this.name = b;
  this.mode = c;
  this.l = {};
  this.m = {};
  this.Ca = d;
}
Object.defineProperties(Eb.prototype, {read:{get:function() {
  return 365 === (this.mode & 365);
}, set:function(a) {
  a ? this.mode |= 365 : this.mode &= -366;
}}, write:{get:function() {
  return 146 === (this.mode & 146);
}, set:function(a) {
  a ? this.mode |= 146 : this.mode &= -147;
}}});
Rb();
xb = Array(4096);
Lb(N, "/");
O("/tmp", 16895, 0);
O("/home", 16895, 0);
O("/home/web_user", 16895, 0);
(() => {
  O("/dev", 16895, 0);
  ib(259, {read:() => 0, write:(d, e, f, g) => g,});
  Mb("/dev/null", 259);
  hb(1280, kb);
  hb(1536, lb);
  Mb("/dev/tty", 1280);
  Mb("/dev/tty1", 1536);
  var a = new Uint8Array(1024), b = 0, c = () => {
    0 === b && (b = $a(a).byteLength);
    return a[--b];
  };
  Ub("random", c);
  Ub("urandom", c);
  O("/dev/shm", 16895, 0);
  O("/dev/shm/tmp", 16895, 0);
})();
(() => {
  O("/proc", 16895, 0);
  var a = O("/proc/self", 16895, 0);
  O("/proc/self/fd", 16895, 0);
  Lb({U:() => {
    var b = ob(a, "fd", 16895, 73);
    b.l = {ma:(c, d) => {
      var e = Ib(+d);
      c = {parent:null, U:{ib:"fake"}, l:{na:() => e.path},};
      return c.parent = c;
    }};
    return b;
  }}, "/proc/self/fd");
})();
Object.assign($b.prototype, {get(a) {
  return this.M[a];
}, has(a) {
  return void 0 !== this.M[a];
}, ta(a) {
  var b = this.eb.pop() || this.M.length;
  this.M[b] = a;
  return b;
}, Sb(a) {
  this.M[a] = void 0;
  this.eb.push(a);
}});
ac = m.BindingError = class extends Error {
  constructor(a) {
    super(a);
    this.name = "BindingError";
  }
};
P.M.push({value:void 0}, {value:null}, {value:!0}, {value:!1},);
P.h = P.M.length;
m.count_emval_handles = function() {
  for (var a = 0, b = P.h; b < P.M.length; ++b) {
    void 0 !== P.M[b] && ++a;
  }
  return a;
};
dc = m.PureVirtualError = cc("PureVirtualError");
for (var Td = Array(256), Ud = 0; 256 > Ud; ++Ud) {
  Td[Ud] = String.fromCharCode(Ud);
}
ec = Td;
m.getInheritedInstanceCount = function() {
  return Object.keys(ic).length;
};
m.getLiveInheritedInstances = function() {
  var a = [], b;
  for (b in ic) {
    ic.hasOwnProperty(b) && a.push(ic[b]);
  }
  return a;
};
m.flushPendingDeletes = gc;
m.setDelayFunction = function(a) {
  hc = a;
  fc.length && hc && hc(gc);
};
vc = m.InternalError = class extends Error {
  constructor(a) {
    super(a);
    this.name = "InternalError";
  }
};
Hc.prototype.isAliasOf = function(a) {
  if (!(this instanceof Hc && a instanceof Hc)) {
    return !1;
  }
  var b = this.g.u.i, c = this.g.o, d = a.g.u.i;
  for (a = a.g.o; b.A;) {
    c = b.pa(c), b = b.A;
  }
  for (; d.A;) {
    a = d.pa(a), d = d.A;
  }
  return b === d && c === a;
};
Hc.prototype.clone = function() {
  this.g.o || Gc(this);
  if (this.g.ka) {
    return this.g.count.value += 1, this;
  }
  var a = yc, b = Object, c = b.create, d = Object.getPrototypeOf(this), e = this.g;
  a = a(c.call(b, d, {g:{value:{count:e.count, ha:e.ha, ka:e.ka, o:e.o, u:e.u, H:e.H, L:e.L,},}}));
  a.g.count.value += 1;
  a.g.ha = !1;
  return a;
};
Hc.prototype["delete"] = function() {
  this.g.o || Gc(this);
  this.g.ha && !this.g.ka && Q("Object already scheduled for deletion");
  pc(this);
  rc(this.g);
  this.g.ka || (this.g.H = void 0, this.g.o = void 0);
};
Hc.prototype.isDeleted = function() {
  return !this.g.o;
};
Hc.prototype.deleteLater = function() {
  this.g.o || Gc(this);
  this.g.ha && !this.g.ka && Q("Object already scheduled for deletion");
  fc.push(this);
  1 === fc.length && hc && hc(gc);
  this.g.ha = !0;
  return this;
};
Qc.prototype.Ob = function(a) {
  this.ob && (a = this.ob(a));
  return a;
};
Qc.prototype.Za = function(a) {
  this.W && this.W(a);
};
Qc.prototype.argPackAdvance = 8;
Qc.prototype.readValueFromPointer = Bc;
Qc.prototype.deleteObject = function(a) {
  if (null !== a) {
    a["delete"]();
  }
};
Qc.prototype.fromWireType = function(a) {
  function b() {
    return this.ya ? xc(this.i.N, {u:this.bc, o:c, L:this, H:a,}) : xc(this.i.N, {u:this, o:a,});
  }
  var c = this.Ob(a);
  if (!c) {
    return this.Za(a), null;
  }
  var d = uc(this.i, c);
  if (void 0 !== d) {
    if (0 === d.g.count.value) {
      return d.g.o = c, d.g.H = a, d.clone();
    }
    d = d.clone();
    this.Za(a);
    return d;
  }
  d = this.i.Nb(c);
  d = tc[d];
  if (!d) {
    return b.call(this);
  }
  d = this.xa ? d.Fb : d.pointerType;
  var e = sc(c, this.i, d.i);
  return null === e ? b.call(this) : this.ya ? xc(d.i.N, {u:d, o:e, L:this, H:a,}) : xc(d.i.N, {u:d, o:e,});
};
Vc = m.UnboundTypeError = cc("UnboundTypeError");
for (var Y, Vd = 0; 32 > Vd; ++Vd) {
  Jd.push(Array(Vd));
}
var Xd = {__syscall_fcntl64:function(a, b, c) {
  Wb = c;
  try {
    var d = Ib(a);
    switch(b) {
      case 0:
        var e = Xb();
        return 0 > e ? -28 : Kb(d, e).X;
      case 1:
      case 2:
        return 0;
      case 3:
        return d.flags;
      case 4:
        return e = Xb(), d.flags |= e, 0;
      case 5:
        return e = Xb(), I[e + 0 >> 1] = 2, 0;
      case 6:
      case 7:
        return 0;
      case 16:
      case 8:
        return -28;
      case 9:
        return J[Wd() >> 2] = 28, -1;
      default:
        return -28;
    }
  } catch (f) {
    if ("undefined" == typeof Vb || "ErrnoError" !== f.name) {
      throw f;
    }
    return -f.aa;
  }
}, __syscall_ioctl:function(a, b, c) {
  Wb = c;
  try {
    var d = Ib(a);
    switch(b) {
      case 21509:
        return d.s ? 0 : -59;
      case 21505:
        if (!d.s) {
          return -59;
        }
        if (d.s.V.Wb) {
          b = [3, 28, 127, 21, 4, 0, 1, 0, 17, 19, 26, 0, 18, 15, 23, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,];
          var e = Xb();
          J[e >> 2] = 25856;
          J[e + 4 >> 2] = 5;
          J[e + 8 >> 2] = 191;
          J[e + 12 >> 2] = 35387;
          for (var f = 0; 32 > f; f++) {
            D[e + f + 17 >> 0] = b[f] || 0;
          }
        }
        return 0;
      case 21510:
      case 21511:
      case 21512:
        return d.s ? 0 : -59;
      case 21506:
      case 21507:
      case 21508:
        if (!d.s) {
          return -59;
        }
        if (d.s.V.Xb) {
          for (e = Xb(), b = [], f = 0; 32 > f; f++) {
            b.push(D[e + f + 17 >> 0]);
          }
        }
        return 0;
      case 21519:
        if (!d.s) {
          return -59;
        }
        e = Xb();
        return J[e >> 2] = 0;
      case 21520:
        return d.s ? -28 : -59;
      case 21531:
        e = Xb();
        if (!d.m.Vb) {
          throw new M(59);
        }
        return d.m.Vb(d, b, e);
      case 21523:
        if (!d.s) {
          return -59;
        }
        d.s.V.Yb && (f = [24, 80], e = Xb(), I[e >> 1] = f[0], I[e + 2 >> 1] = f[1]);
        return 0;
      case 21524:
        return d.s ? 0 : -59;
      case 21515:
        return d.s ? 0 : -59;
      default:
        return -28;
    }
  } catch (g) {
    if ("undefined" == typeof Vb || "ErrnoError" !== g.name) {
      throw g;
    }
    return -g.aa;
  }
}, __syscall_openat:function(a, b, c, d) {
  Wb = d;
  try {
    b = b ? L(G, b) : "";
    var e = b;
    if ("/" === e.charAt(0)) {
      b = e;
    } else {
      var f = -100 === a ? "/" : Ib(a).path;
      if (0 == e.length) {
        throw new M(44);
      }
      b = Wa(f + "/" + e);
    }
    var g = d ? Xb() : 0;
    return Pb(b, c, g).X;
  } catch (n) {
    if ("undefined" == typeof Vb || "ErrnoError" !== n.name) {
      throw n;
    }
    return -n.aa;
  }
}, _embind_create_inheriting_constructor:function(a, b, c) {
  a = S(a);
  b = oc(b, "wrapper");
  c = R(c);
  var d = [].slice, e = b.i, f = e.N, g = e.A.N, n = e.A.constructor;
  a = Zb(a, function() {
    e.A.mb.forEach(function(l) {
      if (this[l] === g[l]) {
        throw new dc(`Pure virtual function ${l} must be implemented in JavaScript`);
      }
    }.bind(this));
    Object.defineProperty(this, "__parent", {value:f});
    this.__construct.apply(this, d.call(arguments));
  });
  f.__construct = function() {
    this === f && Q("Pass correct 'this' to __construct");
    var l = n.implement.apply(void 0, [this].concat(d.call(arguments)));
    pc(l);
    var t = l.g;
    l.notifyOnDestruction();
    t.ka = !0;
    Object.defineProperties(this, {g:{value:t}});
    yc(this);
    l = t.o;
    l = jc(e, l);
    ic.hasOwnProperty(l) ? Q(`Tried to register registered instance: ${l}`) : ic[l] = this;
  };
  f.__destruct = function() {
    this === f && Q("Pass correct 'this' to __destruct");
    pc(this);
    var l = this.g.o;
    l = jc(e, l);
    ic.hasOwnProperty(l) ? delete ic[l] : Q(`Tried to unregister unregistered instance: ${l}`);
  };
  a.prototype = Object.create(f);
  for (var p in c) {
    a.prototype[p] = c[p];
  }
  return bc(a);
}, _embind_finalize_value_object:function(a) {
  var b = zc[a];
  delete zc[a];
  var c = b.Ra, d = b.W, e = b.ab, f = e.map(g => g.Rb).concat(e.map(g => g.fc));
  T([a], f, g => {
    var n = {};
    e.forEach((p, l) => {
      var t = g[l], u = p.Pb, x = p.Qb, k = g[l + e.length], q = p.ec, v = p.hc;
      n[p.Lb] = {read:y => t.fromWireType(u(x, y)), write:(y, C) => {
        var z = [];
        q(v, y, k.toWireType(z, C));
        Ac(z);
      }};
    });
    return [{name:b.name, fromWireType:function(p) {
      var l = {}, t;
      for (t in n) {
        l[t] = n[t].read(p);
      }
      d(p);
      return l;
    }, toWireType:function(p, l) {
      for (var t in n) {
        if (!(t in l)) {
          throw new TypeError(`Missing field: "${t}"`);
        }
      }
      var u = c();
      for (t in n) {
        n[t].write(u, l[t]);
      }
      null !== p && p.push(d, u);
      return u;
    }, argPackAdvance:8, readValueFromPointer:Bc, K:d,}];
  });
}, _embind_register_bigint:function() {
}, _embind_register_bool:function(a, b, c, d, e) {
  var f = Ec(c);
  b = S(b);
  U(a, {name:b, fromWireType:function(g) {
    return !!g;
  }, toWireType:function(g, n) {
    return n ? d : e;
  }, argPackAdvance:8, readValueFromPointer:function(g) {
    if (1 === c) {
      var n = D;
    } else if (2 === c) {
      n = I;
    } else if (4 === c) {
      n = J;
    } else {
      throw new TypeError("Unknown boolean type size: " + b);
    }
    return this.fromWireType(n[g >> f]);
  }, K:null,});
}, _embind_register_class:function(a, b, c, d, e, f, g, n, p, l, t, u, x) {
  t = S(t);
  f = V(e, f);
  n && (n = V(g, n));
  l && (l = V(p, l));
  x = V(u, x);
  var k = Yb(t);
  Jc(k, function() {
    Wc(`Cannot construct ${t} due to unbound types`, [d]);
  });
  T([a, b, c], d ? [d] : [], function(q) {
    q = q[0];
    if (d) {
      var v = q.i;
      var y = v.N;
    } else {
      y = Hc.prototype;
    }
    q = Zb(k, function() {
      if (Object.getPrototypeOf(this) !== C) {
        throw new ac("Use 'new' to construct " + t);
      }
      if (void 0 === z.$) {
        throw new ac(t + " has no accessible constructor");
      }
      var H = z.$[arguments.length];
      if (void 0 === H) {
        throw new ac(`Tried to invoke ctor of ${t} with invalid number of parameters (${arguments.length}) - expected (${Object.keys(z.$).toString()}) parameters instead!`);
      }
      return H.apply(this, arguments);
    });
    var C = Object.create(y, {constructor:{value:q},});
    q.prototype = C;
    var z = new Kc(t, q, C, x, v, f, n, l);
    z.A && (void 0 === z.A.qa && (z.A.qa = []), z.A.qa.push(z));
    v = new Qc(t, z, !0, !1);
    y = new Qc(t + "*", z, !1, !1);
    var E = new Qc(t + " const*", z, !1, !0);
    tc[a] = {pointerType:y, Fb:E};
    Rc(k, q);
    return [v, y, E];
  });
}, _embind_register_class_class_function:function(a, b, c, d, e, f, g) {
  var n = Yc(c, d);
  b = S(b);
  f = V(e, f);
  T([], [a], function(p) {
    function l() {
      Wc(`Cannot call ${t} due to unbound types`, n);
    }
    p = p[0];
    var t = `${p.name}.${b}`;
    b.startsWith("@@") && (b = Symbol[b.substring(2)]);
    var u = p.i.constructor;
    void 0 === u[b] ? (l.ga = c - 1, u[b] = l) : (Ic(u, b, t), u[b].B[c - 1] = l);
    T([], n, function(x) {
      x = Xc(t, [x[0], null].concat(x.slice(1)), null, f, g);
      void 0 === u[b].B ? (x.ga = c - 1, u[b] = x) : u[b].B[c - 1] = x;
      if (p.i.qa) {
        for (const k of p.i.qa) {
          k.constructor.hasOwnProperty(b) || (k.constructor[b] = x);
        }
      }
      return [];
    });
    return [];
  });
}, _embind_register_class_class_property:function(a, b, c, d, e, f, g, n) {
  b = S(b);
  f = V(e, f);
  T([], [a], function(p) {
    p = p[0];
    var l = `${p.name}.${b}`, t = {get() {
      Wc(`Cannot access ${l} due to unbound types`, [c]);
    }, enumerable:!0, configurable:!0};
    t.set = n ? () => {
      Wc(`Cannot access ${l} due to unbound types`, [c]);
    } : () => {
      Q(`${l} is a read-only property`);
    };
    Object.defineProperty(p.i.constructor, b, t);
    T([], [c], function(u) {
      u = u[0];
      var x = {get() {
        return u.fromWireType(f(d));
      }, enumerable:!0};
      n && (n = V(g, n), x.set = k => {
        var q = [];
        n(d, u.toWireType(q, k));
        Ac(q);
      });
      Object.defineProperty(p.i.constructor, b, x);
      return [];
    });
    return [];
  });
}, _embind_register_class_constructor:function(a, b, c, d, e, f) {
  var g = Yc(b, c);
  e = V(d, e);
  T([], [a], function(n) {
    n = n[0];
    var p = `constructor ${n.name}`;
    void 0 === n.i.$ && (n.i.$ = []);
    if (void 0 !== n.i.$[b - 1]) {
      throw new ac(`Cannot register multiple constructors with identical number of parameters (${b - 1}) for class '${n.name}'! Overload resolution is currently only performed using the parameter count, not actual type info!`);
    }
    n.i.$[b - 1] = () => {
      Wc(`Cannot construct ${n.name} due to unbound types`, g);
    };
    T([], g, function(l) {
      l.splice(1, 0, null);
      n.i.$[b - 1] = Xc(p, l, null, e, f);
      return [];
    });
    return [];
  });
}, _embind_register_class_function:function(a, b, c, d, e, f, g, n) {
  var p = Yc(c, d);
  b = S(b);
  f = V(e, f);
  T([], [a], function(l) {
    function t() {
      Wc(`Cannot call ${u} due to unbound types`, p);
    }
    l = l[0];
    var u = `${l.name}.${b}`;
    b.startsWith("@@") && (b = Symbol[b.substring(2)]);
    n && l.i.mb.push(b);
    var x = l.i.N, k = x[b];
    void 0 === k || void 0 === k.B && k.className !== l.name && k.ga === c - 2 ? (t.ga = c - 2, t.className = l.name, x[b] = t) : (Ic(x, b, u), x[b].B[c - 2] = t);
    T([], p, function(q) {
      q = Xc(u, q, l, f, g);
      void 0 === x[b].B ? (q.ga = c - 2, x[b] = q) : x[b].B[c - 2] = q;
      return [];
    });
    return [];
  });
}, _embind_register_class_property:function(a, b, c, d, e, f, g, n, p, l) {
  b = S(b);
  e = V(d, e);
  T([], [a], function(t) {
    t = t[0];
    var u = `${t.name}.${b}`, x = {get() {
      Wc(`Cannot access ${u} due to unbound types`, [c, g]);
    }, enumerable:!0, configurable:!0};
    x.set = p ? () => {
      Wc(`Cannot access ${u} due to unbound types`, [c, g]);
    } : () => {
      Q(u + " is a read-only property");
    };
    Object.defineProperty(t.i.N, b, x);
    T([], p ? [c, g] : [c], function(k) {
      var q = k[0], v = {get() {
        var C = Zc(this, t, u + " getter");
        return q.fromWireType(e(f, C));
      }, enumerable:!0};
      if (p) {
        p = V(n, p);
        var y = k[1];
        v.set = function(C) {
          var z = Zc(this, t, u + " setter"), E = [];
          p(l, z, y.toWireType(E, C));
          Ac(E);
        };
      }
      Object.defineProperty(t.i.N, b, v);
      return [];
    });
    return [];
  });
}, _embind_register_emval:function(a, b) {
  b = S(b);
  U(a, {name:b, fromWireType:function(c) {
    var d = R(c);
    $c(c);
    return d;
  }, toWireType:function(c, d) {
    return bc(d);
  }, argPackAdvance:8, readValueFromPointer:Bc, K:null,});
}, _embind_register_enum:function(a, b, c, d) {
  function e() {
  }
  c = Ec(c);
  b = S(b);
  e.values = {};
  U(a, {name:b, constructor:e, fromWireType:function(f) {
    return this.constructor.values[f];
  }, toWireType:function(f, g) {
    return g.value;
  }, argPackAdvance:8, readValueFromPointer:ad(b, c, d), K:null,});
  Jc(b, e);
}, _embind_register_enum_value:function(a, b, c) {
  var d = oc(a, "enum");
  b = S(b);
  a = d.constructor;
  d = Object.create(d.constructor.prototype, {value:{value:c}, constructor:{value:Zb(`${d.name}_${b}`, function() {
  })},});
  a.values[c] = d;
  a[b] = d;
}, _embind_register_float:function(a, b, c) {
  c = Ec(c);
  b = S(b);
  U(a, {name:b, fromWireType:function(d) {
    return d;
  }, toWireType:function(d, e) {
    return e;
  }, argPackAdvance:8, readValueFromPointer:bd(b, c), K:null,});
}, _embind_register_function:function(a, b, c, d, e, f) {
  var g = Yc(b, c);
  a = S(a);
  e = V(d, e);
  Jc(a, function() {
    Wc(`Cannot call ${a} due to unbound types`, g);
  }, b - 1);
  T([], g, function(n) {
    Rc(a, Xc(a, [n[0], null].concat(n.slice(1)), null, e, f), b - 1);
    return [];
  });
}, _embind_register_integer:function(a, b, c, d, e) {
  b = S(b);
  -1 === e && (e = 4294967295);
  e = Ec(c);
  var f = n => n;
  if (0 === d) {
    var g = 32 - 8 * c;
    f = n => n << g >>> g;
  }
  c = b.includes("unsigned") ? function(n, p) {
    return p >>> 0;
  } : function(n, p) {
    return p;
  };
  U(a, {name:b, fromWireType:f, toWireType:c, argPackAdvance:8, readValueFromPointer:cd(b, e, 0 !== d), K:null,});
}, _embind_register_memory_view:function(a, b, c) {
  function d(f) {
    f >>= 2;
    var g = K;
    return new e(g.buffer, g[f + 1], g[f]);
  }
  var e = [Int8Array, Uint8Array, Int16Array, Uint16Array, Int32Array, Uint32Array, Float32Array, Float64Array,][b];
  c = S(c);
  U(a, {name:c, fromWireType:d, argPackAdvance:8, readValueFromPointer:d,}, {Tb:!0,});
}, _embind_register_std_string:function(a, b) {
  b = S(b);
  var c = "std::string" === b;
  U(a, {name:b, fromWireType:function(d) {
    var e = K[d >> 2], f = d + 4;
    if (c) {
      for (var g = f, n = 0; n <= e; ++n) {
        var p = f + n;
        if (n == e || 0 == G[p]) {
          g = g ? L(G, g, p - g) : "";
          if (void 0 === l) {
            var l = g;
          } else {
            l += String.fromCharCode(0), l += g;
          }
          g = p + 1;
        }
      }
    } else {
      l = Array(e);
      for (n = 0; n < e; ++n) {
        l[n] = String.fromCharCode(G[f + n]);
      }
      l = l.join("");
    }
    nc(d);
    return l;
  }, toWireType:function(d, e) {
    e instanceof ArrayBuffer && (e = new Uint8Array(e));
    var f = "string" == typeof e;
    f || e instanceof Uint8Array || e instanceof Uint8ClampedArray || e instanceof Int8Array || Q("Cannot pass non-string to std::string");
    var g = c && f ? db(e) : e.length;
    var n = Md(4 + g + 1), p = n + 4;
    K[n >> 2] = g;
    if (c && f) {
      eb(e, G, p, g + 1);
    } else {
      if (f) {
        for (f = 0; f < g; ++f) {
          var l = e.charCodeAt(f);
          255 < l && (nc(p), Q("String has UTF-16 code units that do not fit in 8 bits"));
          G[p + f] = l;
        }
      } else {
        for (f = 0; f < g; ++f) {
          G[p + f] = e[f];
        }
      }
    }
    null !== d && d.push(nc, n);
    return n;
  }, argPackAdvance:8, readValueFromPointer:Bc, K:function(d) {
    nc(d);
  },});
}, _embind_register_std_wstring:function(a, b, c) {
  c = S(c);
  if (2 === b) {
    var d = ed;
    var e = fd;
    var f = gd;
    var g = () => va;
    var n = 1;
  } else {
    4 === b && (d = hd, e = jd, f = kd, g = () => K, n = 2);
  }
  U(a, {name:c, fromWireType:function(p) {
    for (var l = K[p >> 2], t = g(), u, x = p + 4, k = 0; k <= l; ++k) {
      var q = p + 4 + k * b;
      if (k == l || 0 == t[q >> n]) {
        x = d(x, q - x), void 0 === u ? u = x : (u += String.fromCharCode(0), u += x), x = q + b;
      }
    }
    nc(p);
    return u;
  }, toWireType:function(p, l) {
    "string" != typeof l && Q(`Cannot pass non-string to C++ string type ${c}`);
    var t = f(l), u = Md(4 + t + b);
    K[u >> 2] = t >> n;
    e(l, u + 4, t + b);
    null !== p && p.push(nc, u);
    return u;
  }, argPackAdvance:8, readValueFromPointer:Bc, K:function(p) {
    nc(p);
  },});
}, _embind_register_value_object:function(a, b, c, d, e, f) {
  zc[a] = {name:S(b), Ra:V(c, d), W:V(e, f), ab:[],};
}, _embind_register_value_object_field:function(a, b, c, d, e, f, g, n, p, l) {
  zc[a].ab.push({Lb:S(b), Rb:c, Pb:V(d, e), Qb:f, fc:g, ec:V(n, p), hc:l,});
}, _embind_register_void:function(a, b) {
  b = S(b);
  U(a, {Zb:!0, name:b, argPackAdvance:0, fromWireType:function() {
  }, toWireType:function() {
  },});
}, _emscripten_get_now_is_monotonic:() => !0, _emval_as:function(a, b, c) {
  a = R(a);
  b = oc(b, "emval::as");
  var d = [], e = bc(d);
  K[c >> 2] = e;
  return b.toWireType(d, a);
}, _emval_call_method:function(a, b, c, d, e) {
  a = nd[a];
  b = R(b);
  c = md(c);
  var f = [];
  K[d >> 2] = bc(f);
  return a(b, c, f, e);
}, _emval_call_void_method:function(a, b, c, d) {
  a = nd[a];
  b = R(b);
  c = md(c);
  a(b, c, null, d);
}, _emval_decref:$c, _emval_get_method_caller:function(a, b) {
  var c = pd(a, b), d = c[0];
  b = d.name + "_$" + c.slice(1).map(function(g) {
    return g.name;
  }).join("_") + "$";
  var e = qd[b];
  if (void 0 !== e) {
    return e;
  }
  var f = Array(a - 1);
  e = od((g, n, p, l) => {
    for (var t = 0, u = 0; u < a - 1; ++u) {
      f[u] = c[u + 1].readValueFromPointer(l + t), t += c[u + 1].argPackAdvance;
    }
    g = g[n].apply(g, f);
    for (u = 0; u < a - 1; ++u) {
      c[u + 1].Hb && c[u + 1].Hb(f[u]);
    }
    if (!d.Zb) {
      return d.toWireType(p, g);
    }
  });
  return qd[b] = e;
}, _emval_get_property:function(a, b) {
  a = R(a);
  b = R(b);
  return bc(a[b]);
}, _emval_incref:function(a) {
  4 < a && (P.get(a).qb += 1);
}, _emval_new_cstring:function(a) {
  return bc(md(a));
}, _emval_new_object:function() {
  return bc({});
}, _emval_run_destructors:function(a) {
  var b = R(a);
  Ac(b);
  $c(a);
}, _emval_set_property:function(a, b, c) {
  a = R(a);
  b = R(b);
  c = R(c);
  a[b] = c;
}, _emval_take_value:function(a, b) {
  a = oc(a, "_emval_take_value");
  a = a.readValueFromPointer(b);
  return bc(a);
}, abort:() => {
  sa("");
}, beginPixelLocalStorageWEBGL:function(a, b, c) {
  (a = w[a].D.ja) && a.beginPixelLocalStorageWEBGL(m.HEAPU32.subarray(c, c + b));
}, decode_image:function(a, b, c) {
  var d = m.images;
  d || (d = new Map(), m.images = d);
  var e = new Image();
  d.set(a, e);
  b = m.HEAP8.subarray(b, b + c);
  c = new Uint8Array(c);
  c.set(b);
  e.src = URL.createObjectURL(new Blob([c], {type:"image/png"}));
  e.onload = function() {
    m._setWebImage(a, e.width, e.height);
  };
}, delete_image:function(a) {
  var b = m.images;
  b && b.get(a) && b.delete(a);
}, emscripten_asm_const_int:(a, b, c) => {
  rd.length = 0;
  var d;
  for (c >>= 2; d = G[b++];) {
    c += 105 != d & c, rd.push(105 == d ? J[c] : xa[c++ >> 1]), ++c;
  }
  return Ta[a].apply(null, rd);
}, emscripten_date_now:function() {
  return Date.now();
}, emscripten_get_now:() => performance.now(), emscripten_memcpy_big:(a, b, c) => G.copyWithin(a, b, b + c), emscripten_resize_heap:a => {
  var b = G.length;
  a >>>= 0;
  if (2147483648 < a) {
    return !1;
  }
  for (var c = 1; 4 >= c; c *= 2) {
    var d = b * (1 + 0.2 / c);
    d = Math.min(d, a + 100663296);
    var e = Math;
    d = Math.max(a, d);
    a: {
      e = e.min.call(e, 2147483648, d + (65536 - d % 65536) % 65536) - ta.buffer.byteLength + 65535 >>> 16;
      try {
        ta.grow(e);
        ya();
        var f = 1;
        break a;
      } catch (g) {
      }
      f = void 0;
    }
    if (f) {
      return !0;
    }
  }
  return !1;
}, emscripten_webgl_enable_extension:function(a, b) {
  a = w[a];
  b = b ? L(G, b) : "";
  b.startsWith("GL_") && (b = b.substr(3));
  "WEBGL_draw_instanced_base_vertex_base_instance" == b && sd(Y);
  "WEBGL_multi_draw_instanced_base_vertex_base_instance" == b && td(Y);
  "WEBGL_multi_draw" == b && Fd(Y);
  return !!a.D.getExtension(b);
}, emscripten_webgl_get_current_context:function() {
  return r ? r.handle : 0;
}, emscripten_webgl_make_context_current:function(a) {
  return ia(a) ? 0 : -5;
}, enable_WEBGL_provoking_vertex:function(a) {
  a = w[a].D;
  a.nb = a.getExtension("WEBGL_provoking_vertex");
  return !!a.nb;
}, enable_WEBGL_shader_pixel_local_storage_coherent:function(a) {
  a = w[a].D;
  a.ja = a.getExtension("WEBGL_shader_pixel_local_storage");
  return !(!a.ja || !a.ja.isCoherent());
}, endPixelLocalStorageWEBGL:function(a, b, c) {
  (a = w[a].D.ja) && a.endPixelLocalStorageWEBGL(m.HEAPU32.subarray(c, c + b));
}, environ_get:(a, b) => {
  var c = 0;
  Id().forEach(function(d, e) {
    var f = b + c;
    e = K[a + 4 * e >> 2] = f;
    for (f = 0; f < d.length; ++f) {
      D[e++ >> 0] = d.charCodeAt(f);
    }
    D[e >> 0] = 0;
    c += d.length + 1;
  });
  return 0;
}, environ_sizes_get:(a, b) => {
  var c = Id();
  K[a >> 2] = c.length;
  var d = 0;
  c.forEach(function(e) {
    d += e.length + 1;
  });
  K[b >> 2] = d;
  return 0;
}, fd_close:function(a) {
  try {
    var b = Ib(a);
    if (null === b.X) {
      throw new M(8);
    }
    b.Oa && (b.Oa = null);
    try {
      b.m.close && b.m.close(b);
    } catch (c) {
      throw c;
    } finally {
      vb[b.X] = null;
    }
    b.X = null;
    return 0;
  } catch (c) {
    if ("undefined" == typeof Vb || "ErrnoError" !== c.name) {
      throw c;
    }
    return c.aa;
  }
}, fd_read:function(a, b, c, d) {
  try {
    a: {
      var e = Ib(a);
      a = b;
      for (var f, g = b = 0; g < c; g++) {
        var n = K[a >> 2], p = K[a + 4 >> 2];
        a += 8;
        var l = e, t = n, u = p, x = f, k = D;
        if (0 > u || 0 > x) {
          throw new M(28);
        }
        if (null === l.X) {
          throw new M(8);
        }
        if (1 === (l.flags & 2097155)) {
          throw new M(8);
        }
        if (16384 === (l.node.mode & 61440)) {
          throw new M(31);
        }
        if (!l.m.read) {
          throw new M(28);
        }
        var q = "undefined" != typeof x;
        if (!q) {
          x = l.position;
        } else if (!l.seekable) {
          throw new M(70);
        }
        var v = l.m.read(l, k, t, u, x);
        q || (l.position += v);
        var y = v;
        if (0 > y) {
          var C = -1;
          break a;
        }
        b += y;
        if (y < p) {
          break;
        }
        "undefined" !== typeof f && (f += y);
      }
      C = b;
    }
    K[d >> 2] = C;
    return 0;
  } catch (z) {
    if ("undefined" == typeof Vb || "ErrnoError" !== z.name) {
      throw z;
    }
    return z.aa;
  }
}, fd_seek:function(a, b, c, d, e) {
  b = c + 2097152 >>> 0 < 4194305 - !!b ? (b >>> 0) + 4294967296 * c : NaN;
  try {
    if (isNaN(b)) {
      return 61;
    }
    var f = Ib(a);
    Qb(f, b, d);
    Pa = [f.position >>> 0, (Oa = f.position, 1.0 <= +Math.abs(Oa) ? 0.0 < Oa ? +Math.floor(Oa / 4294967296.0) >>> 0 : ~~+Math.ceil((Oa - +(~~Oa >>> 0)) / 4294967296.0) >>> 0 : 0)];
    J[e >> 2] = Pa[0];
    J[e + 4 >> 2] = Pa[1];
    f.Oa && 0 === b && 0 === d && (f.Oa = null);
    return 0;
  } catch (g) {
    if ("undefined" == typeof Vb || "ErrnoError" !== g.name) {
      throw g;
    }
    return g.aa;
  }
}, fd_write:function(a, b, c, d) {
  try {
    a: {
      var e = Ib(a);
      a = b;
      for (var f, g = b = 0; g < c; g++) {
        var n = K[a >> 2], p = K[a + 4 >> 2];
        a += 8;
        var l = e, t = n, u = p, x = f, k = D;
        if (0 > u || 0 > x) {
          throw new M(28);
        }
        if (null === l.X) {
          throw new M(8);
        }
        if (0 === (l.flags & 2097155)) {
          throw new M(8);
        }
        if (16384 === (l.node.mode & 61440)) {
          throw new M(31);
        }
        if (!l.m.write) {
          throw new M(28);
        }
        l.seekable && l.flags & 1024 && Qb(l, 0, 2);
        var q = "undefined" != typeof x;
        if (!q) {
          x = l.position;
        } else if (!l.seekable) {
          throw new M(70);
        }
        var v = l.m.write(l, k, t, u, x, void 0);
        q || (l.position += v);
        var y = v;
        if (0 > y) {
          var C = -1;
          break a;
        }
        b += y;
        "undefined" !== typeof f && (f += y);
      }
      C = b;
    }
    K[d >> 2] = C;
    return 0;
  } catch (z) {
    if ("undefined" == typeof Vb || "ErrnoError" !== z.name) {
      throw z;
    }
    return z.aa;
  }
}, framebufferPixelLocalClearValuefvWEBGL:function(a, b, c, d, e, f) {
  (a = w[a].D.ja) && a.framebufferPixelLocalClearValuefvWEBGL(b, [c, d, e, f]);
}, framebufferTexturePixelLocalStorageWEBGL:function(a, b, c, d, e) {
  (a = w[a].D.ja) && a.framebufferTexturePixelLocalStorageWEBGL(b, yd[c], d, e);
}, glActiveTexture:function(a) {
  Y.activeTexture(a);
}, glAttachShader:function(a, b) {
  Y.attachShader(W[a], zd[b]);
}, glBindBuffer:function(a, b) {
  35051 == a ? Y.Ya = b : 35052 == a && (Y.Ma = b);
  Y.bindBuffer(a, vd[b]);
}, glBindBufferRange:function(a, b, c, d, e) {
  Y.bindBufferRange(a, b, vd[c], d, e);
}, glBindFramebuffer:function(a, b) {
  Y.bindFramebuffer(a, wd[b]);
}, glBindRenderbuffer:function(a, b) {
  Y.bindRenderbuffer(a, xd[b]);
}, glBindTexture:function(a, b) {
  Y.bindTexture(a, yd[b]);
}, glBindVertexArray:function(a) {
  Y.bindVertexArray(Ad[a]);
}, glBlendEquation:function(a) {
  Y.blendEquation(a);
}, glBlendFunc:function(a, b) {
  Y.blendFunc(a, b);
}, glBlitFramebuffer:function(a, b, c, d, e, f, g, n, p, l) {
  Y.blitFramebuffer(a, b, c, d, e, f, g, n, p, l);
}, glBufferData:function(a, b, c, d) {
  c && b ? Y.bufferData(a, G, d, c, b) : Y.bufferData(a, b, d);
}, glBufferSubData:function(a, b, c, d) {
  c && Y.bufferSubData(a, b, G, d, c);
}, glClear:function(a) {
  Y.clear(a);
}, glClearColor:function(a, b, c, d) {
  Y.clearColor(a, b, c, d);
}, glClearDepthf:function(a) {
  Y.clearDepth(a);
}, glClearStencil:function(a) {
  Y.clearStencil(a);
}, glColorMask:function(a, b, c, d) {
  Y.colorMask(!!a, !!b, !!c, !!d);
}, glCompileShader:function(a) {
  Y.compileShader(zd[a]);
}, glCreateProgram:function() {
  var a = Dd(W), b = Y.createProgram();
  b.name = a;
  b.Gc = b.Ec = b.Fc = 0;
  b.Ua = 1;
  W[a] = b;
  return a;
}, glCreateShader:function(a) {
  var b = Dd(zd);
  zd[b] = Y.createShader(a);
  return b;
}, glCullFace:function(a) {
  Y.cullFace(a);
}, glDeleteBuffers:function(a, b) {
  for (var c = 0; c < a; c++) {
    var d = J[b + 4 * c >> 2], e = vd[d];
    e && (Y.deleteBuffer(e), e.name = 0, vd[d] = null, d == Y.Ya && (Y.Ya = 0), d == Y.Ma && (Y.Ma = 0));
  }
}, glDeleteFramebuffers:function(a, b) {
  for (var c = 0; c < a; ++c) {
    var d = J[b + 4 * c >> 2], e = wd[d];
    e && (Y.deleteFramebuffer(e), e.name = 0, wd[d] = null);
  }
}, glDeleteProgram:function(a) {
  if (a) {
    var b = W[a];
    b ? (Y.deleteProgram(b), b.name = 0, W[a] = null) : X(1281);
  }
}, glDeleteRenderbuffers:function(a, b) {
  for (var c = 0; c < a; c++) {
    var d = J[b + 4 * c >> 2], e = xd[d];
    e && (Y.deleteRenderbuffer(e), e.name = 0, xd[d] = null);
  }
}, glDeleteShader:function(a) {
  if (a) {
    var b = zd[a];
    b ? (Y.deleteShader(b), zd[a] = null) : X(1281);
  }
}, glDeleteTextures:function(a, b) {
  for (var c = 0; c < a; c++) {
    var d = J[b + 4 * c >> 2], e = yd[d];
    e && (Y.deleteTexture(e), e.name = 0, yd[d] = null);
  }
}, glDeleteVertexArrays:function(a, b) {
  for (var c = 0; c < a; c++) {
    var d = J[b + 4 * c >> 2];
    Y.deleteVertexArray(Ad[d]);
    Ad[d] = null;
  }
}, glDepthFunc:function(a) {
  Y.depthFunc(a);
}, glDepthMask:function(a) {
  Y.depthMask(!!a);
}, glDepthRangef:function(a, b) {
  Y.depthRange(a, b);
}, glDisable:function(a) {
  Y.disable(a);
}, glDrawArrays:function(a, b, c) {
  Y.drawArrays(a, b, c);
}, glDrawArraysInstanced:function(a, b, c, d) {
  Y.drawArraysInstanced(a, b, c, d);
}, glDrawBuffers:function(a, b) {
  for (var c = Jd[a], d = 0; d < a; d++) {
    c[d] = J[b + 4 * d >> 2];
  }
  Y.drawBuffers(c);
}, glDrawElements:function(a, b, c, d) {
  Y.drawElements(a, b, c, d);
}, glDrawElementsInstanced:function(a, b, c, d, e) {
  Y.drawElementsInstanced(a, b, c, d, e);
}, glEnable:function(a) {
  Y.enable(a);
}, glEnableVertexAttribArray:function(a) {
  Y.enableVertexAttribArray(a);
}, glFlush:function() {
  Y.flush();
}, glFramebufferRenderbuffer:function(a, b, c, d) {
  Y.framebufferRenderbuffer(a, b, c, xd[d]);
}, glFramebufferTexture2D:function(a, b, c, d, e) {
  Y.framebufferTexture2D(a, b, c, yd[d], e);
}, glFrontFace:function(a) {
  Y.frontFace(a);
}, glGenBuffers:function(a, b) {
  Kd(a, b, "createBuffer", vd);
}, glGenFramebuffers:function(a, b) {
  Kd(a, b, "createFramebuffer", wd);
}, glGenRenderbuffers:function(a, b) {
  Kd(a, b, "createRenderbuffer", xd);
}, glGenTextures:function(a, b) {
  Kd(a, b, "createTexture", yd);
}, glGenVertexArrays:function(a, b) {
  Kd(a, b, "createVertexArray", Ad);
}, glGenerateMipmap:function(a) {
  Y.generateMipmap(a);
}, glGetIntegerv:function(a, b) {
  Ld(a, b);
}, glGetString:function(a) {
  var b = Bd[a];
  if (!b) {
    switch(a) {
      case 7939:
        b = Y.getSupportedExtensions() || [];
        b = b.concat(b.map(function(d) {
          return "GL_" + d;
        }));
        b = Nd(b.join(" "));
        break;
      case 7936:
      case 7937:
      case 37445:
      case 37446:
        (b = Y.getParameter(a)) || X(1280);
        b = b && Nd(b);
        break;
      case 7938:
        b = Nd("OpenGL ES 3.0 (" + Y.getParameter(7938) + ")");
        break;
      case 35724:
        b = Y.getParameter(35724);
        var c = b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);
        null !== c && (3 == c[1].length && (c[1] += "0"), b = "OpenGL ES GLSL ES " + c[1] + " (" + b + ")");
        b = Nd(b);
        break;
      default:
        X(1280);
    }
    Bd[a] = b;
  }
  return b;
}, glGetUniformBlockIndex:function(a, b) {
  return Y.getUniformBlockIndex(W[a], b ? L(G, b) : "");
}, glGetUniformLocation:function(a, b) {
  b = b ? L(G, b) : "";
  if (a = W[a]) {
    var c = a, d = c.oa, e = c.vb, f;
    if (!d) {
      for (c.oa = d = {}, c.ub = {}, f = 0; f < Y.getProgramParameter(c, 35718); ++f) {
        var g = Y.getActiveUniform(c, f);
        var n = g.name;
        g = g.size;
        var p = Od(n);
        p = 0 < p ? n.slice(0, p) : n;
        var l = c.Ua;
        c.Ua += g;
        e[p] = [g, l];
        for (n = 0; n < g; ++n) {
          d[l] = n, c.ub[l++] = p;
        }
      }
    }
    c = a.oa;
    d = 0;
    e = b;
    f = Od(b);
    0 < f && (d = parseInt(b.slice(f + 1)) >>> 0, e = b.slice(0, f));
    if ((e = a.vb[e]) && d < e[0] && (d += e[1], c[d] = c[d] || Y.getUniformLocation(a, b))) {
      return d;
    }
  } else {
    X(1281);
  }
  return -1;
}, glInvalidateFramebuffer:function(a, b, c) {
  for (var d = Jd[b], e = 0; e < b; e++) {
    d[e] = J[c + 4 * e >> 2];
  }
  Y.invalidateFramebuffer(a, d);
}, glLinkProgram:function(a) {
  a = W[a];
  Y.linkProgram(a);
  a.oa = 0;
  a.vb = {};
}, glPixelStorei:function(a, b) {
  Y.pixelStorei(a, b);
}, glRenderbufferStorageMultisample:function(a, b, c, d, e) {
  Y.renderbufferStorageMultisample(a, b, c, d, e);
}, glScissor:function(a, b, c, d) {
  Y.scissor(a, b, c, d);
}, glShaderSource:function(a, b, c, d) {
  for (var e = "", f = 0; f < b; ++f) {
    var g = d ? J[d + 4 * f >> 2] : -1, n = J[c + 4 * f >> 2];
    g = n ? L(G, n, 0 > g ? void 0 : g) : "";
    e += g;
  }
  Y.shaderSource(zd[a], e);
}, glStencilFunc:function(a, b, c) {
  Y.stencilFunc(a, b, c);
}, glStencilFuncSeparate:function(a, b, c, d) {
  Y.stencilFuncSeparate(a, b, c, d);
}, glStencilMask:function(a) {
  Y.stencilMask(a);
}, glStencilOp:function(a, b, c) {
  Y.stencilOp(a, b, c);
}, glStencilOpSeparate:function(a, b, c, d) {
  Y.stencilOpSeparate(a, b, c, d);
}, glTexParameteri:function(a, b, c) {
  Y.texParameteri(a, b, c);
}, glTexStorage2D:function(a, b, c, d, e) {
  Y.texStorage2D(a, b, c, d, e);
}, glTexSubImage2D:function(a, b, c, d, e, f, g, n, p) {
  if (Y.Ma) {
    Y.texSubImage2D(a, b, c, d, e, f, g, n, p);
  } else if (p) {
    var l = n - 5120;
    l = 0 == l ? D : 1 == l ? G : 2 == l ? I : 4 == l ? J : 6 == l ? wa : 5 == l || 28922 == l || 28520 == l || 30779 == l || 30782 == l ? K : va;
    Y.texSubImage2D(a, b, c, d, e, f, g, n, l, p >> 31 - Math.clz32(l.BYTES_PER_ELEMENT));
  } else {
    Y.texSubImage2D(a, b, c, d, e, f, g, n, null);
  }
}, glUniform1i:function(a, b) {
  var c = Y, d = c.uniform1i;
  var e = Y.Gb;
  if (e) {
    var f = e.oa[a];
    "number" == typeof f && (e.oa[a] = f = Y.getUniformLocation(e, e.ub[a] + (0 < f ? "[" + f + "]" : "")));
    a = f;
  } else {
    X(1282), a = void 0;
  }
  d.call(c, a, b);
}, glUniformBlockBinding:function(a, b, c) {
  a = W[a];
  Y.uniformBlockBinding(a, b, c);
}, glUseProgram:function(a) {
  a = W[a];
  Y.useProgram(a);
  Y.Gb = a;
}, glVertexAttribDivisor:function(a, b) {
  Y.vertexAttribDivisor(a, b);
}, glVertexAttribIPointer:function(a, b, c, d, e) {
  Y.vertexAttribIPointer(a, b, c, d, e);
}, glVertexAttribPointer:function(a, b, c, d, e, f) {
  Y.vertexAttribPointer(a, b, c, !!d, e, f);
}, glViewport:function(a, b, c, d) {
  Y.viewport(a, b, c, d);
}, provokingVertexWEBGL:function(a, b) {
  (a = w[a].D.nb) && a.provokingVertexWEBGL(b);
}, strftime_l:(a, b, c, d) => Sd(a, b, c, d), upload_image:function(a, b) {
  var c = m.images;
  c && (b = c.get(b)) && (a = w[a].D, a.texImage2D(a.TEXTURE_2D, 0, a.RGBA, a.RGBA, a.UNSIGNED_BYTE, b));
}};
(function() {
  function a(c) {
    B = c = c.exports;
    ta = B.memory;
    ya();
    za = B.__indirect_function_table;
    Ba.unshift(B.__wasm_call_ctors);
    Ea--;
    m.monitorRunDependencies && m.monitorRunDependencies(Ea);
    if (0 == Ea && (null !== Fa && (clearInterval(Fa), Fa = null), Ga)) {
      var d = Ga;
      Ga = null;
      d();
    }
    return c;
  }
  var b = {env:Xd, wasi_snapshot_preview1:Xd,};
  Ea++;
  m.monitorRunDependencies && m.monitorRunDependencies(Ea);
  if (m.instantiateWasm) {
    try {
      return m.instantiateWasm(b, a);
    } catch (c) {
      qa("Module.instantiateWasm callback failed with error: " + c), ba(c);
    }
  }
  Na(b, function(c) {
    a(c.instance);
  }).catch(ba);
  return {};
})();
var nc = a => (nc = B.free)(a), Md = a => (Md = B.malloc)(a);
m._setWebImage = (a, b, c) => (m._setWebImage = B.setWebImage)(a, b, c);
var Qa = m._ma_device__on_notification_unlocked = a => (Qa = m._ma_device__on_notification_unlocked = B.ma_device__on_notification_unlocked)(a);
m._ma_malloc_emscripten = (a, b) => (m._ma_malloc_emscripten = B.ma_malloc_emscripten)(a, b);
m._ma_free_emscripten = (a, b) => (m._ma_free_emscripten = B.ma_free_emscripten)(a, b);
var Ra = m._ma_device_process_pcm_frames_capture__webaudio = (a, b, c) => (Ra = m._ma_device_process_pcm_frames_capture__webaudio = B.ma_device_process_pcm_frames_capture__webaudio)(a, b, c), Sa = m._ma_device_process_pcm_frames_playback__webaudio = (a, b, c) => (Sa = m._ma_device_process_pcm_frames_playback__webaudio = B.ma_device_process_pcm_frames_playback__webaudio)(a, b, c), Wd = () => (Wd = B.__errno_location)(), mc = a => (mc = B.__getTypeName)(a);
m.__embind_initialize_bindings = () => (m.__embind_initialize_bindings = B._embind_initialize_bindings)();
m.dynCall_iiji = (a, b, c, d, e) => (m.dynCall_iiji = B.dynCall_iiji)(a, b, c, d, e);
m.dynCall_iiiji = (a, b, c, d, e, f) => (m.dynCall_iiiji = B.dynCall_iiiji)(a, b, c, d, e, f);
m.dynCall_iij = (a, b, c, d) => (m.dynCall_iij = B.dynCall_iij)(a, b, c, d);
m.dynCall_jii = (a, b, c) => (m.dynCall_jii = B.dynCall_jii)(a, b, c);
m.dynCall_jiji = (a, b, c, d, e) => (m.dynCall_jiji = B.dynCall_jiji)(a, b, c, d, e);
m.dynCall_viijii = (a, b, c, d, e, f, g) => (m.dynCall_viijii = B.dynCall_viijii)(a, b, c, d, e, f, g);
m.dynCall_iiiiij = (a, b, c, d, e, f, g) => (m.dynCall_iiiiij = B.dynCall_iiiiij)(a, b, c, d, e, f, g);
m.dynCall_iiiiijj = (a, b, c, d, e, f, g, n, p) => (m.dynCall_iiiiijj = B.dynCall_iiiiijj)(a, b, c, d, e, f, g, n, p);
m.dynCall_iiiiiijj = (a, b, c, d, e, f, g, n, p, l) => (m.dynCall_iiiiiijj = B.dynCall_iiiiiijj)(a, b, c, d, e, f, g, n, p, l);
m.___start_em_js = 502780;
m.___stop_em_js = 505214;
var Yd;
Ga = function Zd() {
  Yd || $d();
  Yd || (Ga = Zd);
};
function $d() {
  function a() {
    if (!Yd && (Yd = !0, m.calledRun = !0, !ua)) {
      m.noFSInit || Sb || (Sb = !0, Rb(), m.stdin = m.stdin, m.stdout = m.stdout, m.stderr = m.stderr, m.stdin ? Ub("stdin", m.stdin) : Nb("/dev/tty", "/dev/stdin"), m.stdout ? Ub("stdout", null, m.stdout) : Nb("/dev/tty", "/dev/stdout"), m.stderr ? Ub("stderr", null, m.stderr) : Nb("/dev/tty1", "/dev/stderr"), Pb("/dev/stdin", 0), Pb("/dev/stdout", 1), Pb("/dev/stderr", 1));
      yb = !1;
      Ua(Ba);
      aa(m);
      if (m.onRuntimeInitialized) {
        m.onRuntimeInitialized();
      }
      if (m.postRun) {
        for ("function" == typeof m.postRun && (m.postRun = [m.postRun]); m.postRun.length;) {
          var b = m.postRun.shift();
          Ca.unshift(b);
        }
      }
      Ua(Ca);
    }
  }
  if (!(0 < Ea)) {
    if (m.preRun) {
      for ("function" == typeof m.preRun && (m.preRun = [m.preRun]); m.preRun.length;) {
        Da();
      }
    }
    Ua(Aa);
    0 < Ea || (m.setStatus ? (m.setStatus("Running..."), setTimeout(function() {
      setTimeout(function() {
        m.setStatus("");
      }, 1);
      a();
    }, 1)) : a());
  }
}
if (m.preInit) {
  for ("function" == typeof m.preInit && (m.preInit = [m.preInit]); 0 < m.preInit.length;) {
    m.preInit.pop()();
  }
}
$d();



  return moduleArg.ready
}

);
})();
export default Rive;